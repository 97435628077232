<template>
  <div class="seed-details-component">
    <div>
      <h4>
        {{ $i18n.translate('masterData.seed.productDetails').toUpperCase() }}
      </h4>
      <hr>
      <SeedDetailsForm v-model="seedDetails" />
    </div>
    <div v-if="!isMaize">
      <h4>
        {{ $i18n.translate('masterData.seed.BSA').toUpperCase() }}
      </h4>
      <hr>
      <OfficialSeedDetailsForm v-model="officialSeedDetails" />
    </div>
    <div v-if="isMaize">
      <h4>
        {{ $i18n.translate('masterData.seed.maize').toUpperCase() }}
      </h4>
      <hr>
      <MaizeDetailsForm v-model="maizeDetails" :selected-product-id="selectedProductId" />
    </div>
    <div>
      <h4>
        {{ $i18n.translate('masterData.seed.Vulnerabilities').toUpperCase() }}
      </h4>
      <hr>
      <VulnerabilitiyScores />
    </div>
    <div>
      <h4>
        {{ $i18n.translate('masterData.seed.mixture').toUpperCase() }}
      </h4>
      <hr>
      <MixtureForm v-model="mixtureComponents" />
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import {mapFormFields} from '@helpers/vuex'
import {extractFormModelsFromProperties} from '@helpers/vue'

import VulnerabilitiyScores from './widgets/VulnerabilitiyScores'
import SeedDetailsForm from './widgets/SeedDetailsForm'
import MaizeDetailsForm from './widgets/MaizeDetailsForm'
import OfficialSeedDetailsForm from './widgets/OfficialSeedDetailsForm'
import MixtureForm from './widgets/MixtureForm'

export default {
  components: {
    VulnerabilitiyScores,
    SeedDetailsForm,
    MaizeDetailsForm,
    OfficialSeedDetailsForm,
    MixtureForm
  },
  computed: {
    ...mapGetters('masterData/management', [
      'isMaize'
    ]),
    ...mapState('masterData/management', {
      selectedProductIds: state => state.ui.selectedProductIds
    }),
    ...mapFormFields('masterData/management', [
      'editBuffer.name',
      'editBuffer.cropId',
      'editBuffer.note',
      'editBuffer.quality',
      'editBuffer.bsA_Culture',
      'editBuffer.bsA_Culture_Short',
      'editBuffer.bsA_Number',
      'editBuffer.minSeedRate',
      'editBuffer.maxSeedRate',
      'editBuffer.maizeMatrix',
      'editBuffer.mixtureComponents'
    ]),
    ...extractFormModelsFromProperties({
      seedDetails: ['name', 'cropId', 'note', 'quality'],
      officialSeedDetails: ['bsA_Culture', 'bsA_Culture_Short', 'bsA_Number'],
      maizeDetails: ['minSeedRate', 'maxSeedRate', 'maizeMatrix']
    }),
    selectedProductId () {
      return this.selectedProductIds.length === 1 ? this.selectedProductIds[0] : null
    }
  }
}
</script>

<style lang="scss" scoped>
.seed-details-component {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content;
  grid-gap: 15px;
}
</style>
