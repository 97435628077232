<template lang="html">
  <div class="search-results">
    <h3>
      <IxRes v-if="!Object.values(result).some(x => x.length)">masterData.crops.headers.noSearchResults</IxRes>
      <IxRes v-else>masterData.crops.headers.searchResults</IxRes>
    </h3>

    <div v-for="(items, level) in result" :key="level" class="results-section">
      <template v-if="items.length">
        <h4>
          <IxRes :context="{0: items.length}">
            masterData.crops.headers.searchResults_{{ level }}
          </IxRes>
        </h4>
        <component
          :is="components[level]"
          v-for="item in items" :key="item.id"
          :item="item"
          @click="$emit('select', item.id) "
        />
      </template>
    </div>
  </div>
</template>

<script>

import CropCard from './CropCard'
import CropUsageCard from './CropUsageCard'
import CropHarvestQualityCard from './CropHarvestQualityCard'

export default {
  components: {
    CropCard,
    CropUsageCard,
    CropHarvestQualityCard
  },
  props: {
    result: {
      type: Object, // {crops, usages, qualities}
      required: true
    }
  },
  computed: {
    components () {
      return {
        crops: CropCard,
        usages: CropUsageCard,
        qualities: CropHarvestQualityCard
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search-results {
  flex: 1;
  display: flex;
  flex-direction: column;

  overflow-y: auto;

  border-top: 1px solid gray;

  .results-section {
    display: flex;
    flex-direction: column;
  }

  h3 {
    margin: 0.25em;
    text-transform: uppercase;
    text-align: center;
  }

  h4 {
    text-transform: uppercase;
    border-bottom: 1px solid gray;
    padding: 0.25em;
  }
}
</style>
