import {groupBy} from 'lodash'

const groupByMultiple = (products, propertyName) => {
  const lookup = {}
  for (const product of products) {
    for (const value of product[propertyName]) {
      if (!lookup[value]) {
        lookup[value] = []
      }
      lookup[value].push(product)
    }
  }
  return lookup
}

const insertHeaders = (groupedProducts, propertyName) => {
  for (const key in groupedProducts) {
    const header = {id: key, name: translate(propertyName, key), count: groupedProducts[key].length, isGroupHeader: true}
    groupedProducts[key].unshift(header)
  }

  return groupedProducts
}

const translate = (propertyName, key) => {
  if (key === 'Repellent, Wildschadenverhütungsmittel') return `masterData.products.headers.${propertyName}_repellentContraceptive`
  if (key === 'Leime, Wachse, Baumharze') return `masterData.products.headers.${propertyName}_glues`
  return `masterData.products.headers.${propertyName}_${key}`
}

const flatten = groupedProducts => Object.entries(groupedProducts).reduce((all, kvp) => all.concat(kvp[1]), [])

const pipeline = (products, propertyName, groupingFunction) => flatten(insertHeaders(groupingFunction(products, propertyName), propertyName))

export const fancyGroup = (products, propertyName, isArray) => pipeline(products, propertyName, isArray ? groupByMultiple : groupBy)
