{waitscreen} = require 'src/js/infrastructure'
{register} = require 'src/js/infrastructure/setup-registry'
{antiForgeryPost, post} = require 'src/coffee/infrastructure/ajax'

{getModal} = require 'src/coffee/controls/modals'

{makeSuccessHandler, makeErrorHandler} = require 'src/coffee/helpers/async/notifications'

setup = ($ctx, promise) ->
    $newSatScenesGrid = $ctx.find('#new-sat-scenes')
    tableNewSatScenes = $newSatScenesGrid.staticgrid()[0]

    $openSatScenesModal = $ctx.find('#open-sat-scenes-modal')
    tableNewSatScenes.bindToSelection $openSatScenesModal

    $existingSatScenesGrid = $ctx.find('#existing-sat-scenes')
    tableExistingSatScenes = $existingSatScenesGrid.staticgrid()[0]

    $openSatScenesModal.on 'click', ->
        satScene = tableNewSatScenes.table.row('.selected').data()
        modalUrl = $openSatScenesModal.data 'url'

        getModal modalUrl, {satScene}, antiForgeryPost
        .then ($modal) ->
            $modal.find('#save-button').on 'click', ->
                $form = $modal.find('#sat-scene-form')
                return unless $form.valid()
                
                satScene =
                    directoryPath: $modal.find('#directory-path').val()
                    name: $modal.find('#name').val()
                    description: $modal.find('#description').val()
                    recorded: $modal.find('#recorded').data('DateTimePicker').date()
                    provider: $modal.find('#provider').val()
                    region: $modal.find('#region').val()
                    channels: []
                
                $channels = $modal.find('.channel')
                
                for channel in $channels
                    $channel = $(channel)
                    satScene.channels.push
                        fileName: $channel.data 'file-name'
                        type: $channel.val()
                    
                url = $modal.find('#save-button').data 'url'
                promise = antiForgeryPost url, {satScene}
                    .then ->
                        tableNewSatScenes.table.row('.selected').remove().draw()
                        tableExistingSatScenes.table.ajax.reload null, false
                    .then makeSuccessHandler $modal.find('#save-button')
                    .fail makeErrorHandler $modal.find('#save-button')

                    .always -> $modal.modal('hide')

                waitscreen.waitFor promise

    eventCounter = 0

    saveChanges = (grid, buffer) ->
        if buffer
            eventCounter++
            selectedRowCount = grid.table.rows('.selected').count()
            return unless eventCounter is selectedRowCount

        saveEditedScenes(grid)

        eventCounter = 0

    tableExistingSatScenes.editor.on 'postEdit', -> saveChanges tableExistingSatScenes, true
    tableExistingSatScenes.editor.on 'postRemove', -> saveChanges tableExistingSatScenes

saveEditedScenes = (grid) ->
    $hiddenDiv = $('#save')

    url = $hiddenDiv.data 'url'
    data =
        rasterDatas: grid.state.changes()

    grid.state.reset()

    promise = antiForgeryPost url, data
    .done makeSuccessHandler $hiddenDiv
    .fail makeErrorHandler $hiddenDiv
    .done ->
        #$('#new-sat-scenes').staticgrid()[0].table.ajax.reload null, false
        $('#existing-sat-scenes').staticgrid()[0].table.ajax.reload null, false

    waitscreen.waitFor promise

register('App.RasterDataManagement.Setup', setup)
