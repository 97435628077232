<template>
  <div class="fertilizer-details-component">
    <div>
      <h4>
        {{ $i18n.translate('masterData.seed.productDetails').toUpperCase() }}
      </h4>
      <hr>
      <FertilizerDetailForm />
    </div>
    <div v-if="isOrganic">
      <h4>
        {{ $i18n.translate('masterData.nutrientHeader').toUpperCase() }}
      </h4>
      <hr>
      <OrganicFertilizerDetails />
    </div>
    <div v-if="!isOrganic">
      <h4>
        {{ $i18n.translate('masterData.nutrientHeader').toUpperCase() }}
      </h4>
      <hr>
      <MineralFertilizerDetails />
    </div>
  </div>
</template>

<script>
import {mapFormFields} from '@helpers/vuex'

import FertilizerDetailForm from './widgets/FertilizerDetailForm'
import MineralFertilizerDetails from './widgets/MineralFertilizerDetails'
import OrganicFertilizerDetails from './widgets/OrganicFertilizerDetails'

export default {
  components: {
    FertilizerDetailForm,
    MineralFertilizerDetails,
    OrganicFertilizerDetails
  },
  computed: {
    ...mapFormFields('masterData/management', [
      'editBuffer.isOrganic'
    ])
  }
}
</script>

<style scoped>
.fertilizer-details-component {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content;
  grid-gap: 15px;
}
</style>
