<template lang="html">
  <div class="component">
    <div class="group-selection">
      <IxButton large :colored="selectedType === 'fertilizer'" @click="withGuard(() => setSelectedProductGroup('fertilizer'))">
        <IxRes>masterData.fertilization</IxRes>
      </IxButton>
      <IxButton :colored="selectedType === 'seed'" large @click="withGuard(() => setSelectedProductGroup('seed'))">
        <IxRes>masterData.seed</IxRes>
      </IxButton>
      <IxButton large :colored="selectedType === 'protection'" @click="withGuard(() => setSelectedProductGroup('protection'))">
        <IxRes>masterData.protection</IxRes>
      </IxButton>
    </div>

    <div class="product-management">
      <div class="product-management-title">
        <h3 class="title">
          {{ title }}
        </h3>
      </div>
      <div class="product-content">
        <div class="product-list">
          <NavigationHeaderSearch @searchResult="searchResult = $event" />
          <GroupingSelection />
          <NavigationHeaderSelectionInfo :selected="selectedProductIds" :filtered="filteredProducts" />
          <NewProductTile />

          <!-- https://github.com/Akryum/vue-virtual-scroller -->
          <!-- the actual size of a single product card is 59, to get some padding we set the item > 59 -->
          <RecycleScroller
            class="scroller" :items="filteredProducts"
            :item-size="64" key-field="id"
          >
            <div slot-scope="{item}">
              <ListCard
                v-if="item.isGroupHeader"
                :id="item.id"
                :title="item.name"
                title-translate
              >
                <template #subtitle>
                  {{ item.count }}
                  <IxRes v-if="item.count <= 1">masterData.product</IxRes>
                  <IxRes v-else>masterData.products</IxRes>
                </template>
              </ListCard>
              <ProductCard
                v-else
                :id="item.id"
                :product="item"
                :selected="selectedIdLookup[item.id]"
                :class="{grouped: groupKey != null}"
                show-avatar
                @click.native="withGuard(() => select(item.id))"
              >
                <template #avatar>
                  <BsCheckbox
                    v-show="!(selectedProductIds.length === 1 && selectedIdLookup[item.id])"
                    :state="!!selectedIdLookup[item.id]" @input="saveResetCheckbox(item.id)"
                    @click.native.stop
                  />
                </template>
              </ProductCard>
            </div>
          </RecycleScroller>

          <div class="product-list-footer">
            <IxButton add large @click="withGuard(() => createNewProduct())" />
          </div>
        </div>

        <div class="product-view" style="position: relative">
          <ProductSelectionList v-if="displayMultipleSelectionSummary" class="product-selection-list" />
          <component
            :is="component" v-if="displayDetails"
            :key="componentKey" class="product-details"
          />
          <HelpBox v-if="fieldStates.mixture === 'error'" type="danger">
            <IxRes>masterData.mixture.components.incomplete</IxRes>
          </HelpBox>
          <ProductActionButtons
            class="product-detail-actions"
            @save="onSave"
            @cancel="withGuard(() => clearSelection())"
            @archive="showDeleteModal = true"
          />
          <FrsLoadingIndicator :loading="saving" saving />
        </div>
      </div>
    </div>
    <ConfirmationModal
      ref="modal"
      v-model="showConfirmationModal"
      @cancel="checkForReset"
    >
      <template #title>
        <IxRes>masterData.unsavedChanges</IxRes>
      </template>

      <IxRes>masterData.discardChangesQuestion</IxRes>
    </ConfirmationModal>

    <ConfirmationModal
      v-model="showDeleteModal"
      @confirm="archive"
      @cancel="showModal = false"
    >
      <template #title>
        <IxRes :context="{productType: subtitle, productName}">masterData.DeleteProduct</IxRes>
      </template>
      <IxRes>masterData.DeleteProductQuestion</IxRes>
    </ConfirmationModal>
  </div>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex'

import {mapResources, mapFormFields} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import ChangeDetectionMixin from '@components/forms/ChangeDetectionMixin'
import FormPartMixin from '@components/forms/FormPartMixin'

import IxButton from '@components/IxButton'
import BsCheckbox from '@components/bootstrap/BsCheckbox'

import ConfirmationModal from '@components/modals/ConfirmationModal'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import NavigationHeaderSearch from './NavigationHeaderSearch'
import NavigationHeaderSelectionInfo from './NavigationHeaderSelectionInfo'
import NewProductTile from './NewProductTile'
import ProtectionDetails from './ProtectionDetails'
import FertilizerDetails from './FertilizerDetails'
import SeedDetails from './SeedDetails'

import GroupingSelection from './widgets/GroupingSelection'
import ProductActionButtons from './widgets/ProductActionButtons'
import ProductSelectionList from './widgets/ProductSelectionList'

import ListCard from '@components/card/ListCard'
import ProductCard from './ProductCard'
import HelpBox from '@components/help/HelpBox'

import {fancyGroup} from './group-filter'

export default {
  components: {
    IxButton,
    ProtectionDetails,
    BsCheckbox,
    ListCard,
    ProductCard,
    SeedDetails,
    ProductActionButtons,
    ProductSelectionList,
    ConfirmationModal,
    NavigationHeaderSearch,
    NavigationHeaderSelectionInfo,
    NewProductTile,
    GroupingSelection,
    FrsLoadingIndicator,
    HelpBox
  },
  mixins: [
    ChangeDetectionMixin,
    FormPartMixin
  ],
  data () {
    return {
      searchText: '',
      keys: ['name'],
      searchResult: null,
      showConfirmationModal: false,
      showDeleteModal: false,
      resetCheckboxId: null,
      saving: false
    }
  },
  computed: {
    ...mapResources([
      'Messages.State.SR_StateMessages',
      'masterData'
    ]),
    ...mapFormFields('masterData/management', [
      'ui.groupKey'
    ]),
    ...mapFormFields('masterData/management/editBuffer', [
      'mixtureComponents'
    ]),
    ...mapState('masterData/management', {
      productName: state => state.editBuffer ? state.editBuffer.name : '',
      selectedProductIds: state => state.ui.selectedProductIds,
      selectedType: state => state.ui.selectedType
    }),
    ...mapState('masterData/management', {
      productHash: state => state.ui.productHash
    }),
    ...mapGetters('masterData/management', [
      'displayNewProduct',
      'displayDetails',
      'displayMultipleSelectionSummary'
    ]),
    ...mapGetters('masterData', [
      'productArray'
    ]),
    componentKey () {
      return `type: ${this.selectedType} hash_${this.productHash || 'null'}`
    },
    selectedIdLookup () {
      const lookup = {}
      for (const id of this.selectedProductIds) {
        lookup[id] = true
      }
      return lookup
    },
    component () {
      switch (this.selectedType) {
      case 'fertilizer': return FertilizerDetails
      case 'seed': return SeedDetails
      case 'protection': return ProtectionDetails
      default: throw new Error(`invalid product group: ${this.selectedType}`)
      }
    },
    title () {
      return this.masterData.productManagement.toUpperCase()
    },
    subtitle () {
      return this.selectedType
    },
    filteredProducts () {
      const filtered = (this.searchResult || this.productArray).filter(product => product.type === this.selectedType && !product.isArchived)

      if (!this.groupKey) return filtered

      return fancyGroup(filtered, this.groupKey, ['usage', 'category'].includes(this.groupKey))
    }
  },
  methods: {
    ...mapMutations('masterData/management', [
      'setSelectedProductGroup',
      'createNewProduct',
      'clearSelection'
    ]),
    ...mapActions('masterData/management', [
      'select',
      'toggle',
      'save',
      'archiveSelection'
    ]),
    saveResetCheckbox (id) {
      this.selectedIdLookup[id] = true
      this.resetCheckboxId = id
      this.withGuard(() => this.toggle(id))
    },
    withGuard (action) {
      return this.waitForConfirmationIfNecessary()
      .then(action)
      .catch(() => {})
    },
    waitForConfirmationIfNecessary () {
      return this.displayNewProduct || this.hasChanges
        ? this.$refs.modal.show()
        : Promise.resolve()
    },
    async onSave () {
      this.validate()
      if (this.selectedType === 'seed') {
        this.validateMixture()
      }

      await this.$nextTick()
      if (this.state === 'error') return

      this.saving = true
      try {
        const id = await this.save()
        notifications.success(this.SR_StateMessages.SaveSuccess)
        return this.select(id)
      } catch (error) {
        notifications.error(this.SR_StateMessages.SaveError)
        console.error(error)
      } finally {
        this.saving = false
      }
    },
    archive () {
      return this.archiveSelection()
      .then(() => {
        notifications.success(this.SR_StateMessages.DeleteSuccess)
      })
      .catch(error => {
        notifications.error(this.SR_StateMessages.DeleteError)
        console.error(error)
      })
    },
    checkForReset () {
      if (this.resetCheckboxId != null) {
        this.selectedIdLookup[this.resetCheckboxId] = false
        this.resetCheckboxId = null
      }
    },
    onSearchResult (result) {
      this.clearSelection()
      this.searchResult = result
    },
    validateMixture () {
      if (this.mixtureComponents.length > 0) {
        const sum = this.mixtureComponents.reduce((sum, x) => sum + x.percentage, 0)
        this.$set(this.fieldStates, 'mixture', Number.parseFloat(sum.toFixed(4)) === 1 ? 'success' : 'error')
      } else {
        this.$delete(this.fieldStates, 'mixture')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$header-height: 60px;
$border-none:  0px none #ddd;

.component {
  display: flex;
  width: 100%;

  .group-selection {
    display: flex;
    flex-direction: column;
    margin-top: $header-height;

    button {
      border: 1px solid #ddd;
    }

    button:first-of-type {
      border-bottom: $border-none
    }

    button:last-of-type {
      border-top: $border-none
    }
  }

  .product-management {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    flex: 1;

    .product-management-title {
      min-height: $header-height;
      height: $header-height;
      font-size: 1.5em;
    }

    .product-content {
      display: flex;
      flex: 1;
      align-items: stretch;
      height: 90%;
      min-height: 0; // firefox scrollbar issue
    }

    .product-list {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 400px;
      min-width: 400px;
      max-width: 400px;

      .scroller {
        flex: 1;
      }

      .product-list-footer {
        margin: 10px 0 0 0;
      }

      .product-list {
          margin-bottom: 6px;
      }

      .grouped {
        margin-left: 20px;
      }
    }

    .product-view {
      margin-left: 20px;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;

      .product-details {
        overflow-y: auto;
        overflow-x: hidden;

        > * {
          margin-right: 20px;
        }
      }

      .product-detail-actions {
        padding: 10px 0 0 0;
      }

      .product-selection-list {
        overflow: auto;
      }
    }
  }

  .modal-action-buttons {
    display: flex;
    justify-content: space-evenly;
  }
}

</style>
