# CoffeeScript

{register} = require 'src/js/infrastructure/setup-registry'
{notifications} = require 'src/js/infrastructure'
{makeErrorHandler} = require 'src/coffee/helpers/async/notifications'
{makeRefreshHandler} = require 'src/coffee/helpers/async/fragment-loading'
{post} = require 'src/coffee/infrastructure/ajax'

setup = ($ctx) ->
    $deletePost = $ctx.find ('#deletePost')
    grid = $('#editableGrid').staticgrid()[0]

    deleteButtonTitle = $deletePost.data 'buttonTitle'
    deleteSuccessMessage = $deletePost.data 'delete-success-message'
    deleteErrorMessage = $deletePost.data 'delete-error-message'

    grid.editor.on 'preOpen', ->
        grid.editor.buttons(deleteButtonTitle)
    grid.editor.on 'postRemove', ->
        url = $deletePost.data 'url'
        data = grid.state.changes()

        post url, data, false
        .then makeRefreshHandler()
        .done ->
            if $('#errorPage').length is 1
                notifications.error deleteErrorMessage
            else
                notifications.success deleteSuccessMessage
        .fail makeErrorHandler deleteErrorMessage

register('App.MasterData.SetupCropTypeView', setup)
