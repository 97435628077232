<template>
  <div class="oxide-form-container">
    <div v-if="isOxideForm" class="nutrient-container">
      <div class="item">
        <p>
          <IxRes>
            masterData.phosphorOxid
          </IxRes>
        </p>
        <NumericInput
          v-model="phosphorusOxide"
          percent percent-not-normalized
          name="phosphorusOxide"
          class="numeric-input"
          :rule="between(0, 100)"
          hide-description
        />
      </div>
      <div class="item">
        <p>
          <IxRes>
            masterData.potassiumOxid
          </IxRes>
        </p>
        <NumericInput
          v-model="potassiumOxide"
          percent percent-not-normalized
          name="potassiumOxide"
          class="numeric-input"
          :rule="between(0, 100)"
          hide-description
        />
      </div>
      <div class="item">
        <p>
          <IxRes>
            masterData.magnesiumOxid
          </IxRes>
        </p>
        <NumericInput
          v-model="magnesiumOxide"
          percent percent-not-normalized
          name="magnesiumOxide"
          class="numeric-input"
          :rule="between(0, 100)"
          hide-description
        />
      </div>
    </div>

    <div v-else class="nutrient-container">
      <div class="item">
        <p>
          <IxRes>
            masterData.phosphorus
          </IxRes>
        </p>
        <NumericInput
          v-model="phosphorus"
          percent percent-not-normalized
          name="phosphorus"
          class="numeric-input"
          :rule="between(0, 100)"
          hide-description
        />
      </div>
      <div class="item">
        <p>
          <IxRes>
            masterData.potassium
          </IxRes>
        </p>
        <NumericInput
          v-model="potassium"
          percent percent-not-normalized
          name="potassium"
          class="numeric-input"
          :rule="between(0, 100)"
          hide-description
        />
      </div>
      <div class="item">
        <p>
          <IxRes>
            masterData.magnesium
          </IxRes>
        </p>
        <NumericInput
          v-model="magnesium"
          percent percent-not-normalized
          name="magnesium"
          class="numeric-input"
          :rule="between(0, 100)"
          hide-description
        />
      </div>
    </div>

    <BsCheckbox v-model="isOxideForm" class="oxide-checkbox">
      <IxRes>
        masterData.IsOxidForm
      </IxRes>
    </BsCheckbox>
  </div>
</template>

<script>

import {mapFormFields} from '@helpers/vuex'

import NumericInput from '@components/forms/NumericInput'
import BsCheckbox from '@components/bootstrap/BsCheckbox'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'

import {calculateFromOxidform, calculateToOxidform} from '@frs/components/basic-fertilization/oxidform-calculation'

export default {
  components: {
    BsCheckbox,
    NumericInput
  },
  mixins: [StandardRulesMixin],
  data () {
    return {
      isOxideForm: false
    }
  },
  computed: {
    ...mapFormFields('masterData/management', [
      'editBuffer.phosphorus',
      'editBuffer.potassium',
      'editBuffer.magnesium'
    ]),
    phosphorusOxide: {
      get () {
        return calculateToOxidform('phosphorus', this.phosphorus)
      },
      set (value) {
        this.phosphorus = calculateFromOxidform('phosphorus', value)
      }
    },
    potassiumOxide: {
      get () {
        return calculateToOxidform('potassium', this.potassium)
      },
      set (value) {
        this.potassium = calculateFromOxidform('potassium', value)
      }
    },
    magnesiumOxide: {
      get () {
        return calculateToOxidform('magnesium', this.magnesium)
      },
      set (value) {
        this.magnesium = calculateFromOxidform('magnesium', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.oxide-form-container {

  display: flex;

  .oxide-checkbox {
    padding-top: 39px;
    margin-left: 8px;
  }

  .nutrient-container {
    display: flex;
    flex-direction: column;

    .item {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 8px;

      p {
        text-align: right;
        padding: 5px 6px 0 0;
        width: 200px;
      }

      .numeric-input {
        width: 200px; /* give input fixed, otherwise the input changes in size when entering something */
      }
    }
  }
}

</style>
