<template>
  <div>
    <h4>
      <IxRes>
        masterData.severalProductsSelected
      </IxRes>
    </h4>
    <ul>
      <li v-for="productId in selectedProductIds" :key="productId">
        {{ products[productId].name }}
      </li>
    </ul>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

import {mapFormFields} from '@helpers/vuex'

export default {
  computed: {
    ...mapFormFields('masterData/management', [
      'ui.selectedProductIds'
    ]),
    ...mapGetters('masterData', [
      'products'
    ])
  }
}
</script>

<style>
</style>
