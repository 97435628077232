<template>
  <div>
    <IxButton
      v-if="displayArchiveButton"
      remove
      large
      @click="$emit('archive')"
    />
    <IxButton
      v-if="displaySaveButton"
      save
      large
      @click="$emit('save')"
    />
    <IxButton
      v-if="displayDiscardButton"
      cancel
      large
      @click="$emit('cancel')"
    />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

import IxButton from '@components/IxButton'

export default {
  components: {
    IxButton
  },
  computed: {
    ...mapGetters('masterData/management', [
      'displaySaveButton',
      'displayArchiveButton',
      'displayDiscardButton'
    ])
  }
}
</script>

<style>
</style>
