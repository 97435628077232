<template lang="html">
  <div class="nutrient-removal-editor">
    <header>
      <IxRes>masterData.crops.nutrientRemoval.header</IxRes>
    </header>

    <div class="input-table">
      <div /><!-- for empty top-left grid cell, to work around having to assign all other elements to offset grid cells -->
      <label>
        <IxRes>masterData.crops.nutrientRemoval.labels.mainproduct</IxRes><span v-if="required">&nbsp;*</span>
      </label>
      <label>
        <IxRes>masterData.crops.nutrientRemoval.labels.byproduct</IxRes><span v-if="required">&nbsp;*</span>
      </label>
      <label>
        <IxRes>masterData.crops.nutrientRemoval.labels.total</IxRes>
      </label>
      <template v-for="nutrient in nutrients">
        <div :key="`${nutrient.name}-label`" class="cell-wrapper">
          <label>{{ showAsOxide && nutrient.oxideName ? nutrient.oxideName : nutrient.abbreviation }}</label>
        </div>
        <div :key="`${nutrient.name}-mainproduct-input`" class="cell-wrapper">
          <NumericInput
            :value="processedValue[nutrient.valuePropertyNames.mainproduct]"
            :name="`${name}-${nutrient}-mainproduct`"
            unit="kg/dt" :required="required"
            @input="emitChange('mainproduct', nutrient, $event)"
          />
        </div>
        <div :key="`${nutrient.name}-byproduct-input`" class="cell-wrapper">
          <NumericInput
            :value="processedValue[nutrient.valuePropertyNames.byproduct]"
            :name="`${name}-${nutrient}-byproduct`"
            unit="kg/dt" :required="required"
            @input="emitChange('byproduct', nutrient, $event)"
          />
        </div>
        <div :key="`${nutrient.name}-total`" class="cell-wrapper total">
          <strong v-if="sums[nutrient.name]" class="amount">{{ $i18n.format(sums[nutrient.name]) }} </strong>
          &nbsp;
          <span class="unit">{{ $i18n.translateUnit('kg/dt') }}</span>
        </div>
      </template>
    </div>

    <footer>
      <BsRadio v-model="showAsOxide" :value="false" inline>
        <IxRes>masterData.crops.nutrientRemoval.labels.rawNutrient</IxRes>
      </BsRadio>
      <BsRadio v-model="showAsOxide" :value="true" inline>
        <IxRes>masterData.crops.nutrientRemoval.labels.oxide</IxRes>
      </BsRadio>
    </footer>
  </div>
</template>

<script>
import {mapFormFields} from '@helpers/vuex'
import {
  oxideNames,
  calculateToOxidform,
  calculateFromOxidform
} from '@frs/components/basic-fertilization/oxidform-calculation'

import BsRadio from '@components/bootstrap/BsRadio'
import NumericInput from '@components/forms/NumericInput'

const nutrients = [
  {
    abbreviation: 'N',
    name: 'nitrogen',
    valuePropertyNames: {
      mainproduct: 'mainproductNitrogenRemoval',
      byproduct: 'byproductNitrogenRemoval'
    }
  },
  {
    abbreviation: 'P',
    name: 'phosphorus',
    valuePropertyNames: {
      mainproduct: 'mainproductPhosphorusRemoval',
      byproduct: 'byproductPhosphorusRemoval'
    }
  },
  {
    abbreviation: 'K',
    name: 'potassium',
    valuePropertyNames: {
      mainproduct: 'mainproductPotassiumRemoval',
      byproduct: 'byproductPotassiumRemoval'
    }
  },
  {
    abbreviation: 'Mg',
    name: 'magnesium',
    valuePropertyNames: {
      mainproduct: 'mainproductMagnesiumRemoval',
      byproduct: 'byproductMagnesiumRemoval'
    }
  }
].map(nutrient => ({
  ...nutrient,
  oxideName: oxideNames[nutrient.abbreviation]
}))

export default {
  components: {
    BsRadio,
    NumericInput
  },
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: Object, // still undecided if flat or nested
      required: true
    },
    required: Boolean,
    productShares: Object
  },
  computed: {
    ...mapFormFields('masterData/cropManagement', [
      'ui.showAsOxide'
    ]),
    sums () {
      return nutrients.reduce((lookup, nutrient) => {
        const mainproductValue = this.processedValue[nutrient.valuePropertyNames.mainproduct] * this.productShares.mainproductShare
        const byproductValue = this.processedValue[nutrient.valuePropertyNames.byproduct] * this.productShares.byproductShare

        if (mainproductValue === null || byproductValue === null) {
          lookup[nutrient.name] = null
        } else {
          lookup[nutrient.name] = mainproductValue + byproductValue
        }

        return lookup
      }, {})
    },
    processedValue () {
      // formatting this.value is not necessary here because the value is not changed and therefore no rounding errors occur
      if (!this.showAsOxide) return this.value
      const self = this
      return nutrients.reduce((nutrientRemoval, nutrient) => {
        for (const variant of ['mainproduct', 'byproduct']) {
          const propertyName = nutrient.valuePropertyNames[variant]

          const rawValue = self.value[propertyName]

          if (nutrient.name === 'nitrogen') {
            nutrientRemoval[propertyName] = rawValue
          } else {
            // Usually the value emitted by the NumericInput goes directly back to the NumericInput as value.
            // However, if the oxide form (radio button) is on, the input is converted which results in small rounding errors.
            // As a result the NumericInput does not take the actual input value from the user for display, but the formatted value (see overrideInputText, displayValue).
            // Example: the input "1," gets converted into 1.0000001, which means that the formatted value 1 is displayed (see DLG-1664)
            // Therefore we format the calculated value to avoid these rounding errors.
            nutrientRemoval[propertyName] = rawValue !== null
              ? this.$i18n.parse(this.$i18n.format(calculateToOxidform(nutrient.name, rawValue)), 'number')
              : null
          }
        }
        return nutrientRemoval
      }, {})
    },
    nutrients () {
      return nutrients
    }
  },
  methods: {
    emitChange (variant, nutrient, value) {
      const rawValue = this.showAsOxide && nutrient.name !== 'nitrogen' && value !== null
        ? calculateFromOxidform(nutrient.name, value)
        : value

      this.$emit('input', {
        ...this.value,
        [nutrient.valuePropertyNames[variant]]: rawValue
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.nutrient-removal-editor {
  display: flex;
  flex-direction: column;

  header {
    display: flex;
    justify-content: center;

    font-size: 1.5em;
    font-weight: lighter;

    text-transform: uppercase;
  }

  .input-table {
    display: grid;
    grid-template: repeat(5, auto) / repeat(4, auto);

    ::v-deep .input-field {
      width: 150px;
      margin: 0.5em;
    }

    // margins/borders/colors
    > {
      :nth-child(4n+4) {
        border-left: 2px solid black;
      }

      :nth-child(-n+4) {
        display: flex;
        justify-content: center;
        padding-top: 0.5em;
      }

      :nth-child(n+9) {
        background-color: rgb(221, 221, 221);
      }

      > label {
        margin: 0;
      }

      .cell-wrapper {
        display: flex;
        align-items: center;

        > label {
          width: 2em;
        }

        &:nth-of-type(4n+1), &:nth-of-type(4n-2) {
          padding: 0.5em;
        }
      }

      .total {
        width: 120px;
        display: flex;
        justify-content: flex-end;
        font-size: 1.25em;
      }
    }
  }

  footer {
    background-color: rgb(221, 221, 221);
    display: flex;
    justify-content: center;
    padding: 0.5em;
  }
}
</style>
