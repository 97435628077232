import { render, staticRenderFns } from "./OrganicFertilizerDetails.vue?vue&type=template&id=08432308&scoped=true&lang=html&"
import script from "./OrganicFertilizerDetails.vue?vue&type=script&lang=js&"
export * from "./OrganicFertilizerDetails.vue?vue&type=script&lang=js&"
import style0 from "./OrganicFertilizerDetails.vue?vue&type=style&index=0&id=08432308&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08432308",
  null
  
)

export default component.exports