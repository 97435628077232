<template lang="html">
  <div class="organic-container">
    <div class="item-group">
      <div class="item">
        <p><IxRes>masterData.dryMatter</IxRes></p>
        <NumericInput
          v-model="drySubstrateAmount"
          name="drySubstrateAmount"
          percent percent-not-normalized
          class="numeric-input"
          :rule="between(0, 100)"
          hide-description
        />
      </div>
      <div class="item">
        <p><IxRes>masterData.sulfur</IxRes></p>
        <NumericInput
          v-model="sulfur"
          name="sulfur"
          percent percent-not-normalized
          class="numeric-input"
          :rule="between(0, 100)"
          hide-description
        />
      </div>
    </div>

    <div class="item-group">
      <div class="item">
        <p><IxRes>masterData.nitrogen</IxRes></p>
        <NumericInput
          v-model="nitrogen"
          name="nitrogen"
          :addon="nitrogenUnit"
          class="numeric-input"
          :rule="min(0)"
          hide-description
        />
      </div>
      <div class="item">
        <p>
          <IxRes>masterData.minimumEfficiency</IxRes>
        </p>
        <NumericInput
          v-model="minimumEfficiency"
          name="nitrogen"
          percent percent-not-normalized
          class="numeric-input"
          :rule="between(0, 100)"
          hide-description
        />
      </div>
      <div class="item">
        <p><IxRes>masterData.ammonium</IxRes></p>
        <NumericInput
          v-model="ammonium"
          name="ammonium"
          percent percent-not-normalized
          class="numeric-input"
          :rule="between(0, 100)"
          hide-description
        />
      </div>
      <div class="item">
        <p><IxRes>masterData.nitrat</IxRes></p>
        <NumericInput
          v-model="nitrate"
          name="nitrate"
          percent percent-not-normalized
          class="numeric-input"
          :rule="between(0, 100)"
          hide-description
        />
      </div>
      <div class="item">
        <p><IxRes>masterData.amid</IxRes></p>
        <NumericInput
          v-model="nitrogenAmide"
          name="nitrogenAmide"
          percent percent-not-normalized
          class="numeric-input"
          :rule="between(0, 100)"
          hide-description
        />
      </div>
      <div class="item">
        <p><IxRes>masterData.tanContent</IxRes></p>
        <NumericInput
          v-model="tanContent"
          addon="kg TAN / kg N"
          name="tanContent"
          class="numeric-input"
          :rule="min(0)"
          hide-description
        />
      </div>
    </div>

    <div class="item-group">
      <OxideFormWidget />
    </div>

    <div class="item-group">
      <div class="item">
        <p><IxRes>masterData.calcium</IxRes></p>
        <NumericInput
          v-model="lime"
          name="lime"
          percent percent-not-normalized
          class="numeric-input"
          :rule="between(0, 100)"
          hide-description
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapFormFields} from '@helpers/vuex'

import NumericInput from '@components/forms/NumericInput'
import OxideFormWidget from './OxideFormWidget'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'

export default {
  components: {
    NumericInput,
    OxideFormWidget
  },
  mixins: [StandardRulesMixin],
  computed: {
    ...mapFormFields('masterData/management', [
      'editBuffer.drySubstrateAmount',
      'editBuffer.sulfur',
      'editBuffer.nitrogen',
      'editBuffer.ammonium',
      'editBuffer.nitrate',
      'editBuffer.nitrogenAmide',
      'editBuffer.lime',
      'editBuffer.tanContent',
      'editBuffer.minimumEfficiency',
      'editBuffer.isLiquid'
    ]),
    nitrogenUnit () {
      return this.isLiquid
        ? 'kg N/m3'
        : 'kg N/t'
    }
  }
}
</script>

<style lang="scss" scoped>

.organic-container {

  display: flex;
  flex-direction: column;

  .item-group {
    margin-top: 16px;
  }

  .item {
    display: flex;
    margin-bottom: 8px;

    p {
      text-align: right;
      padding: 5px 6px 0 0;
      width: 200px;
    }

    .numeric-input {
      width: 200px; /* give input fixed, otherwise the input changes in size when entering something */
    }
  }
}

</style>
