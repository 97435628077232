<template lang="html">
  <CardLayout
    v-bind="$attrs"
    :item="item"
    :compact="!counts"
    v-on="$listeners"
  >
    <template #default>
      <IxRes>masterData.crops.crop.{{ item.id }}_name</IxRes>
    </template>
    <template #description>
      <IxRes v-if="count === 1" :context="{0: count}">
        masterData.crops.navigation.count_usages_singular
      </IxRes>
      <IxRes v-else :context="{0: count}">
        masterData.crops.navigation.count_usages_plural
      </IxRes>
    </template>
  </CardLayout>
</template>

<script>

import CardLayout from '../shared/CardLayout'

export default {
  components: {
    CardLayout
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    counts: Object
  },
  computed: {
    count () {
      return this.counts.usage
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
