<template>
  <div class="protection-details">
    <div class="details-title">
      <h4><IxRes>masterData.protection.productDetails</IxRes></h4>
    </div>
    <hr>
    <div class="detail-columns">
      <div class="column-data">
        <div class="column-content">
          <ProductDetails />

          <VueSelectize
            v-model="tags"
            :options="tagOptions"
            :fields="tagFields"
            name="tag-selectize"
            multiple
            allow-empty
          >
            <template #label>
              <IxRes>masterData.product.labels.tags</IxRes>:
            </template>
          </VueSelectize>

          <BsRadioList
            v-model="isLiquid" :options="[false, true]"
            name="isLiquid"
            compact
          >
            <template #default="{option}">
              <IxRes>masterData.{{ option ? 'liquid' : 'solid' }}</IxRes>
            </template>
            <template #label>
              <IxRes>masterData.consistency</IxRes>:
            </template>
          </BsRadioList>

          <BsCheckboxList
            v-model="category" :options="supportedCategories"
            name="category"
            show-toggle-all compact
          >
            <template #label>
              <IxRes>masterData.protectionGroup</IxRes>:
            </template>
          </BsCheckboxList>

          <TextInput
            v-model="substance"
            name="activeSubstance"
            multiline
          >
            <template #label>
              <IxRes>masterData.activeSubstance</IxRes>:
            </template>
          </TextInput>
        </div>
      </div>
      <div class="column-data">
        <div class="column-content">
          <TextInput
            v-model="approvalNumber"
            name="approvalNumber"
          >
            <template #label>
              <IxRes>masterData.registrationNumber</IxRes>:
            </template>
          </TextInput>

          <BsCheckboxList
            v-model="usage" :options="supportedUsages"
            name="usage"
            show-toggle-all compact
          >
            <template #label>
              <IxRes>masterData.application</IxRes>:
            </template>
          </BsCheckboxList>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'

import TextInput from '@components/forms/TextInput'
import BsRadioList from '@components/bootstrap/BsRadioList'
import BsCheckboxList from '@components/bootstrap/BsCheckboxList'
import VueSelectize from '@components/VueSelectize'
import ProductDetails from './widgets/ProductDetails'

import supportedUsages from './supported-protection-usages'
import supportedCategories from './supported-protection-categories'

export default {
  components: {
    VueSelectize,
    TextInput,
    BsRadioList,
    ProductDetails,
    BsCheckboxList
  },
  mixins: [
    StandardRulesMixin
  ],
  computed: {
    supportedUsages () {
      return supportedUsages
    },
    supportedCategories () {
      return supportedCategories
    },
    ...mapState('masterData', {
      productTags: state => state.productTags
    }),
    ...mapFormFields('masterData/management', [
      'editBuffer.category',
      'editBuffer.activeSubstance',
      'editBuffer.approvalNumber',
      'editBuffer.isLiquid',
      'editBuffer.usage',
      'editBuffer.tags'
    ]),
    tagOptions () {
      if (!this.productTags.length) return []

      return this.productTags.filter(tag => {
        const splittedTag = tag.split(':')
        if (splittedTag[0] === 'Pflanzenschutz') {
          return tag
        }
      })
    },
    tagFields () {
      return {
        text: tag => {
          const splittedTag = tag.split(':')
          return splittedTag[splittedTag.length - 1]
        },
        value: tag => tag
      }
    },
    substance: {
      get () {
        return this.activeSubstance.join()
      },
      set (value) {
        this.activeSubstance = [value]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.protection-details {
  display: flex;
  flex: 1;
  flex-direction: column;

  hr {
    margin: 0 0 16px 0;
    padding: 0;
  }

  h4 {
    text-transform: uppercase;
  }

  .detail-columns {
    display: flex;
    flex: 1;

    .column-data {
      width: 50%;

      .column-content {
        display: flex;
        flex-direction: column;
        width: 80%;

        > * {
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
