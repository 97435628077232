import { render, staticRenderFns } from "./TranslatedField.vue?vue&type=template&id=966a3aba&scoped=true&lang=html&"
import script from "./TranslatedField.vue?vue&type=script&lang=js&"
export * from "./TranslatedField.vue?vue&type=script&lang=js&"
import style0 from "./TranslatedField.vue?vue&type=style&index=0&id=966a3aba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "966a3aba",
  null
  
)

export default component.exports