<template>
  <div class="product-selection-info">
    <h4 v-if="selected.length > 0">
      {{ selected.length }}/{{ filtered.length }} <IxRes>masterData.selectedProducts</IxRes>
    </h4>
    <h4 v-else>
      {{ filtered.length }} <IxRes>masterData.productsAvailable</IxRes>
    </h4>
  </div>
</template>

<script>

export default {
  props: {
    selected: Array,
    filtered: Array
  }
}
</script>

<style lang="scss" scoped>

.product-selection-info {
  margin: 10px 0 10px 20px;
}

</style>
