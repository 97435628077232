<template lang="html">
  <div class="Breadcrumbs-link" :style="style">
    <strong v-if="isCurrent">
      <i class="fa fa-level-up fa-rotate-90" />
      <IxRes>masterData.crops.{{ level }}.{{ id }}_name</IxRes>
    </strong>
    <a v-else href="#" @click.prevent="$emit('click')">
      <i class="fa fa-level-up fa-rotate-90" />
      <IxRes>masterData.crops.{{ level }}.{{ id }}_name</IxRes>
    </a>
  </div>
</template>

<script>

import BreadcrumbsIndentMixin from './BreadcrumbsIndentMixin'

export default {
  mixins: [
    BreadcrumbsIndentMixin
  ],
  props: {
    level: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    isCurrent: Boolean
  }
}
</script>

<style lang="scss" scoped>
.fa {
  margin: 0 0.25em;
}
</style>
