<template lang="html">
  <transition>
    <div class="disabled-overlay" />
  </transition>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.disabled-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;

  background-color: rgb(222, 222, 222);
  opacity: 0.75;
}

.v-enter, .v-leave-to {
  opacity: 0;
}

.v-enter-active, .v-leave-active {
  transition: opacity 0.25s;
}
</style>
