<template lang="html">
  <DetailsLayout>
    <template #left>
      <TranslatedField v-model="name" name="name" required>
        <template #label>
          <IxRes>masterData.crops.labels.name</IxRes>:
        </template>
      </TranslatedField>

      <TranslatedField v-model="abbreviation" name="abbreviation" required>
        <template #label>
          <IxRes>masterData.crops.labels.abbreviation</IxRes>:
        </template>
      </TranslatedField>
    </template>
    <template #right>
      <NumericInput
        v-model="yieldLevel"
        name="yieldLevel"
        unit="dt/ha"
        :rule="rules.yieldLevel"
      >
        <template #label>
          <IxRes>masterData.crops.labels.yieldLevel</IxRes>:
        </template>
      </NumericInput>

      <NumericInput
        v-model="nitrogenRequirement"
        name="nitrogenRequirement"
        unit="kg/ha"
        :rule="rules.nitrogenRequirement"
      >
        <template #label>
          <IxRes>masterData.crops.labels.nitrogenRequirement</IxRes>:
        </template>
      </NumericInput>

      <NumericInput
        v-model="drySubstrateAmount"
        name="drySubstrateAmount"
        percent percent-not-normalized
        :rule="rules.drySubstrateAmount"
      >
        <template #label>
          <IxRes>masterData.crops.labels.drySubstrateAmount</IxRes>:
        </template>
      </NumericInput>

      <TextInput v-model="subsidyCode" name="subsidyCode">
        <template #label>
          <IxRes>masterData.crops.labels.subsidyCode</IxRes>:
        </template>
      </TextInput>
    </template>
  </DetailsLayout>
</template>

<script>
import TextInput from '@components/forms/TextInput'
import NumericInput from '@components/forms/NumericInput'

import {mapFormFields, mapResources} from '@helpers/vuex'

import DetailsLayout from './shared/DetailsLayout'
import TranslatedField from './widgets/TranslatedField'

export default {
  components: {
    TextInput,
    NumericInput,
    DetailsLayout,
    TranslatedField
  },
  computed: {
    ...mapFormFields('masterData/cropManagement', [
      'editBuffer.name',
      'editBuffer.abbreviation',
      'editBuffer.yieldLevel',
      'editBuffer.nitrogenRequirement',
      'editBuffer.drySubstrateAmount',
      'editBuffer.subsidyCode'
    ]),
    ...mapResources({
      validationMessages: 'masterData.crops.validation'
    }),
    rules () {
      return {
        yieldLevel: x => (x >= 0) || this.validationMessages.negativeYieldLevel,
        nitrogenRequirement: x => (x >= 0) || this.validationMessages.negativeNitrogenRequirement,
        drySubstrateAmount: x => (x >= 0 && x <= 100) || this.validationMessages.drySubstrateAmountOutOfBounds
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .left .translated-field:last-of-type .input-field {
    width: 100px;
  }

  .right {
    align-items: flex-start;

    > div > .input-field {
      width: 150px;
    }
  }
}
</style>
