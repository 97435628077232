{waitscreen} = require 'src/js/infrastructure'
{register} = require 'src/js/infrastructure/setup-registry'
{post} = require 'src/coffee/infrastructure/ajax'
{notifications} = require 'src/js/infrastructure'
{makeRefreshHandler} = require 'src/coffee/helpers/async/fragment-loading'
{makeSuccessHandler} = require 'src/coffee/helpers/async/notifications'

setup = ($ctx) ->
    $ctx.find('#save-all-button').on 'click', -> saveRegions $('.region-form')
    $ctx.find('.save-region-button').on 'click', -> saveRegions $(this).closest('.region-form')

saveRegions = ($regions) ->
    cropTypeName = $('#croptype').val().trim()
    $('#croptype').val(cropTypeName)

    isFormValid = $('#crop-type-name').valid()
    regionsValid = $regions.valid()
    if isFormValid and regionsValid

        $panel = $ '#croptype-panel'

        cropTypes = $panel.data 'crop-types'

        url = $panel.data 'url'
        data = getModel $regions

        for ct in cropTypes
            if data.cropTypeName.toLowerCase() is ct.toLowerCase()
                notifications.error $panel.data('duplicate-name-message')
                return
        promise = post url, data
        .done makeRefreshHandler()
        .then makeSuccessHandler $panel

        waitscreen.waitFor promise


getModel = ($regions) ->
    $cropType = $ '#croptype'

    cropTypeId = $cropType.data 'croptype-id'
    cropTypeName = $cropType.val()

    model =
        productAlgorithms: $('#algorithms').val()
        cropTypeId: cropTypeId
        cropTypeName: cropTypeName
        regionalNutrients: []

    $regions.each ->
        model.regionalNutrients.push getRegionData $(this)

    return model


getRegionData = ($region) ->

    # todo: add clientside validation (serverside exists)

    regionId = $region.data 'region-id'
    ctniId = $region.data 'croptypenutrientinfo-id'

    ratio = $region.find('input[name=ratio]').val()
    $nutrientPanels = $region.find '.nutrient-panel'

    # sending the regionId alongside a valid ctniId is a bit redundant (the region won't change, but it makes life easier)
    region =
        ratio: ratio
        regionId: regionId
        regionName: null # just in case the binder screws up
        nutrients: []

    $nutrientPanels.each ->
        $panel = $(this)

        ctniId = $panel.data 'croptype-nutrientinfo-id'
        nutrientId = $panel.data 'nutrient-id'
        nutrientName = $panel.data 'nutrient-sanitized-name'
        regionName = $panel.data 'region-sanitized-name'
        mainProductId = '#' + regionName + '-' + nutrientName + '-mainproduct'
        byProductId = '#' + regionName + '-' + nutrientName + '-byproduct'
        mainProduct = $(mainProductId).val()
        byProduct = $(byProductId).val()

        region.nutrients.push
            cropTypeNutrientInfoId: ctniId || null
            nutrientId: nutrientId
            nutrientName: $panel.data 'nutrient-name'
            mainProductConsumption: mainProduct
            byProductConsumption: byProduct

    return region

register('App.MasterData.Administration.SetupCropTypeNutrientInfo', setup)
