<template lang="html">
  <CardLayout
    v-bind="$attrs"
    :item="item"
    compact
    v-on="$listeners"
  >
    <IxRes>masterData.crops.quality.{{ item.id }}_name</IxRes>
  </CardLayout>
</template>

<script>

import CardLayout from '../shared/CardLayout'

export default {
  components: {
    CardLayout
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
