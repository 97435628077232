<template lang="html">
  <div class="landing-page">
    <div class="search-help">
      <IxRes>masterData.crops.help.search</IxRes>
    </div>
    <div class="breadcrumbs-help">
      <IxRes>masterData.crops.help.breadcrumbs</IxRes>
    </div>
    <div class="list-help">
      <IxRes>masterData.crops.help.navigation1</IxRes>
      <IxRes>masterData.crops.help.navigation2</IxRes>
    </div>
    <div class="button-help">
      <IxRes>masterData.crops.help.addButton</IxRes>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.landing-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0.75em;

  .breadcrumbs-help {
    margin-top: 2.5em;
  }

  .list-help {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 16em;
  }

  .button-help {
    margin-top: auto;
    margin-bottom: 0.25em;
  }
}
</style>
