<template lang="html">
  <DetailsLayout>
    <template #left>
      <TranslatedField v-model="name" name="name" required>
        <template #label>
          <IxRes>masterData.crops.labels.name</IxRes>:
        </template>
      </TranslatedField>
      <VueSelectize
        v-model="tags"
        name="tags"
        :fields="fields"
        :options="cropTags"
        multiple
        allow-empty
      >
        <template #label>
          <IxRes>masterData.crops.labels.tags</IxRes>:
        </template>
      </VueSelectize>
    </template>
    <template #right>
      <BsRadioList v-model="isLeguminous" name="isLeguminous" :options="[true, false]">
        <template #default="{option}">
          <IxRes v-if="option">masterData.crops.labels.isLeguminousTrue</IxRes>
          <IxRes v-else>masterData.crops.labels.isLeguminousFalse</IxRes>
        </template>
        <template #label>
          <IxRes>masterData.crops.labels.isLeguminous</IxRes>:
        </template>
      </BsRadioList>
      <NumericInput
        v-if="isLeguminous"
        v-model="nFix"
        addon="kg/dt FM"
        name="nFix"
        class="n-fix-input"
        required
        hide-description
        :rule="min(0)"
      >
        <template #label>
          <IxRes>masterData.crops.labels.nFix</IxRes>:
        </template>
      </NumericInput>
      <NumericInput
        v-model="averageWeight"
        unit="g"
        name="averageWeight"
        class="average-weight"
        hide-description
        :rule="min(0)"
      >
        <template #label>
          <IxRes>masterData.crops.labels.averageWeight</IxRes>:
        </template>
      </NumericInput>
    </template>
  </DetailsLayout>
</template>

<script>
import {mapState} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

import BsRadioList from '@components/bootstrap/BsRadioList'
import VueSelectize from '@components/VueSelectize'
import NumericInput from '@components/forms/NumericInput'

import DetailsLayout from './shared/DetailsLayout'
import TranslatedField from './widgets/TranslatedField'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'

export default {
  components: {
    NumericInput,
    VueSelectize,
    BsRadioList,
    DetailsLayout,
    TranslatedField
  },
  mixins: [
    StandardRulesMixin
  ],
  computed: {
    ...mapFormFields('masterData/cropManagement', [
      'editBuffer.name',
      'editBuffer.cropGroup',
      'editBuffer.tags',
      'editBuffer.isLeguminous',
      'editBuffer.nFix',
      'editBuffer.averageWeight'
    ]),
    ...mapState('masterData', {
      cropTags: state => state.cropTags
    }),
    fields () {
      return {
        text: tag => {
          const splittedTag = tag.split(':')
          return splittedTag[splittedTag.length - 1]
        },
        group: tag => {
          const splittedTag = tag.split(':')
          return tag.replace(`${splittedTag[splittedTag.length - 1]}`, '')
        },
        value: tag => tag
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.n-fix-input {
  width: 25%;
}
.average-weight{
  width: 25%;
}
</style>
