import translate from 'src/js/i18n/translate'
import supportedCategories from './components/supported-protection-categories'

export const toProductMetadataInfo = (product) => {
  switch (product.type) {
  case 'seed':
    return translate(`masterData.crops.crop.${product.cropId}_name`)
  case 'protection':
    return product.category.map(category => translate(supportedCategories.find(supported => supported.value === category).label)).join(', ')
  case 'fertilizer':
    return `${product.isOrganic ? translate('masterData.organic') : translate('masterData.mineral')}, ${product.isLiquid ? translate('masterData.liquid') : translate('masterData.solid')}`
  default :
    throw new Error('product type unknown')
  }
}
