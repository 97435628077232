export default [
  {value: 'Ackerbau', label: 'masterData.product.usages.agriculture'},
  {value: 'Grünland', label: 'masterData.product.usages.greenland'},
  {value: 'Getreide', label: 'masterData.product.usages.corn'},
  {value: 'Gemüsebau', label: 'masterData.product.usages.olericulture'},
  {value: 'Obstbau', label: 'masterData.product.usages.orcharding'},
  {value: 'Mais', label: 'masterData.product.usages.maize'},
  {value: 'Hopfenbau', label: 'masterData.product.usages.hopGrowing'},
  {value: 'Forst', label: 'masterData.product.usages.forest'},
  {value: 'Kartoffel', label: 'masterData.product.usages.potatos'},
  {value: 'Raps', label: 'masterData.product.usages.rape'},
  {value: 'Weinbau', label: 'masterData.product.usages.viniculture'},
  {value: 'Zierpflanzenbau', label: 'masterData.product.usages.ornamentalPlantsGrowing'}
]
