<template lang="html">
  <div class="card" :class="{indented, selected, archived: item.isArchived, 'needs-cleanup': item.needsCleanup}" @click="onClick">
    <div class="text">
      <div class="main">
        <i v-if="item.needsCleanup" class="fa fa-warning" title="migrated and unchecked" />
        <slot />
        <i v-if="item.isArchived" class="fa fa-archive" title="archived" />
      </div>
      <div v-if="!compact" class="description">
        <slot name="description" />
      </div>
    </div>
    <i v-if="!selected" class="fa fa-angle-right" />
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    selected: Boolean,
    indented: Boolean,
    compact: Boolean
  },
  methods: {
    onClick () {
      if (!this.selected) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  padding: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-of-type) {
    border-bottom: 1px solid lightgray;
  }

  transition: background-color 0.25s;

  &:hover {
    background-color: rgb(240, 240, 240);
  }

  &.indented {
    padding-left: 2em;
  }

  &.selected {
    background-color: rgb(159, 197, 249);
  }

  &:not(.selected) {
    cursor: pointer;
  }

  > i {
    font-size: 2em;
  }

  .text {
    display: flex;
    flex-direction: column;

    .main {
      font-size: 1.4em;
    }

    .description {
      font-size: 0.8em;
      color: gray;
    }
  }

  &.archived {
    opacity: 0.25;
  }
}

.needs-cleanup {
  .main {
    color: red;
  }
}
</style>
