import { render, staticRenderFns } from "./BreadcrumbsSummary.vue?vue&type=template&id=7aa4524a&scoped=true&lang=html&"
import script from "./BreadcrumbsSummary.vue?vue&type=script&lang=js&"
export * from "./BreadcrumbsSummary.vue?vue&type=script&lang=js&"
import style0 from "./BreadcrumbsSummary.vue?vue&type=style&index=0&id=7aa4524a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7aa4524a",
  null
  
)

export default component.exports