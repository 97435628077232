<template lang="html">
  <DetailsLayout>
    <template #left>
      <TranslatedField v-model="name" name="Name" required>
        <template #label>
          <IxRes>masterData.crops.labels.name</IxRes>
        </template>
      </TranslatedField>
    </template>
    <template #right>
      <NumericInput
        v-model="crudeProteinContent"
        name="crudeProteinContent"
        percent percent-not-normalized
        :rule="rules.crudeProteinContent"
      >
        <template #label>
          <IxRes>masterData.crops.labels.crudeProteinContent</IxRes>:
        </template>
      </NumericInput>

      <NutrientRemovalEditor
        v-model="nutrientRemoval"
        :product-shares="productShares"
        name="nutrientRemoval"
        required
      />

      <ProductSharesEditor v-model="productShares" name="productShares" required>
        <template #label>
          <IxRes>masterData.crops.labels.productShares</IxRes>:
        </template>
      </ProductSharesEditor>
    </template>
  </DetailsLayout>
</template>

<script>

import NumericInput from '@components/forms/NumericInput'

import {mapFormFields, mapResources} from '@helpers/vuex'

import DetailsLayout from './shared/DetailsLayout'
import TranslatedField from './widgets/TranslatedField'
import NutrientRemovalEditor from './widgets/NutrientRemovalEditor'
import ProductSharesEditor from './widgets/ProductSharesEditor'

export default {
  components: {
    NumericInput,
    DetailsLayout,
    TranslatedField,
    NutrientRemovalEditor,
    ProductSharesEditor
  },
  computed: {
    ...mapFormFields('masterData/cropManagement', [
      'editBuffer.name',
      'editBuffer.crudeProteinContent'
    ]),
    ...mapFormFields('masterData/cropManagement', {
      nutrientRemoval: {
        read (value, state, getters) {
          return getters.nutrientRemoval
        }
      },
      productShares: {
        read (value, state, getters) {
          return getters.productShares
        }
      }
    }),
    ...mapResources({
      validationMessages: 'masterData.crops.validation'
    }),
    rules () {
      return {
        crudeProteinContent: x => (x >= 0 && x <= 100) || this.validationMessages.crudeProteinContentOutOfBounds
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .right {
    align-items: flex-start;

    > div > .input-field {
      width: 150px;
    }
  }
}
</style>
