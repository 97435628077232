<template>
  <Search
    v-model="searchText" class="search"
    :data="useProducts"
    :keys="['name']"
    :threshold="0.3"
    @result="$emit('searchResult', $event)"
  />
</template>

<script>
import {mapState, mapGetters} from 'vuex'

import Search from '@components/search/Search'

export default {
  components: {
    Search
  },
  props: {
    products: Array
  },
  data () {
    return {
      searchText: ''
    }
  },
  computed: {
    ...mapState('masterData/management', {
      selectedProductIds: state => state.ui.selectedProductIds
    }),
    ...mapGetters('masterData', [
      'productArray'
    ]),
    useProducts () {
      return this.products !== undefined ? this.products : this.productArray
    }
  }
}
</script>

<style>
</style>
