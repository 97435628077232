<template lang="html">
  <LabelAndMessage class="translated-field" v-bind="$attrs">
    <div v-for="(translation, locale) in value" :key="locale" class="locale-input-row">
      <label :for="`${name}_${locale}`">
        <IxRes>masterData.crops.labels.localeInput_{{ locale }}</IxRes>:
      </label>
      <TextInput
        class="locale-input"
        :name="`${name}_${locale}`"
        :value="translation"
        v-bind="$attrs"
        @input="emitChange(locale, $event)"
      />
    </div>
    <template #label>
      <slot name="label" />
    </template>
  </LabelAndMessage>
</template>

<script>
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import TextInput from '@components/forms/TextInput'

export default {
  components: {
    LabelAndMessage,
    TextInput
  },
  props: {
    value: {
      type: Object, // {[locale]: String}
      required: true
    },
    name: String
  },
  methods: {
    emitChange (locale, value) {
      this.$emit('input', {...this.value, [locale]: value})
    }
  }
}
</script>

<style lang="scss" scoped>
.translated-field {
  .locale-input-row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 0.6em;

    label {
      font-size: 1.2em;
      font-weight: normal;
      margin-right: 0.6em;
    }

    .locale-input {
      width: 60%;
      flex-shrink: 1;
    }
  }
}
</style>
