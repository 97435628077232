<template lang="html">
  <div class="Breadcrumbs-statistics" :style="style">
    <i v-if="showIcon" class="fa fa-level-up fa-rotate-90" />

    <IxRes v-if="count === 1" :context="{0: count}">
      masterData.crops.breadcrumbs.count_{{ level }}_singular
    </IxRes>
    <IxRes v-else :context="{0: count}">
      masterData.crops.breadcrumbs.count_{{ level }}_plural
    </IxRes>
  </div>
</template>

<script>

import BreadcrumbsIndentMixin from './BreadcrumbsIndentMixin'

export default {
  mixins: [
    BreadcrumbsIndentMixin
  ],
  props: {
    level: {
      type: String,
      required: true
    },
    count: {
      type: Number,
      required: true
    },
    showIcon: Boolean
  }
}
</script>

<style lang="scss" scoped>
.fa {
  margin: 0 0.25em;
}
</style>
