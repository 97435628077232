<template lang="html">
  <div class="navigation-list">
    <CropCard
      v-if="location.selectionLevel === 'crop'"
      :item="current"
      selected
      @click="$emit('select', location.cropId)"
    />
    <CropUsageCard
      v-else-if="location.selectionLevel"
      :item="combinedLookup[location.cropUsageId]"
      :selected="location.selectionLevel === 'usage'"
      @click="$emit('select', location.cropUsageId)"
    />

    <template v-for="item in sortedListItems">
      <CropCard
        v-if="location.selectionLevel === null" :key="item.id"
        :item="item"
        :indented="location.selectionLevel !== null"
        :counts="countsById[item.id]"
        @click="$emit('select', item.id)"
      />
      <CropUsageCard
        v-else-if="location.selectionLevel === 'crop'" :key="item.id"
        :item="item"
        :indented="location.selectionLevel !== null"
        :counts="countsById[item.id]"
        @click="$emit('select', item.id)"
      />
      <CropHarvestQualityCard
        v-else :key="item.id"
        :item="item"
        :indented="location.selectionLevel !== null"
        :selected="item.id === selection"
        @click="$emit('select', item.id)"
      />
    </template>
  </div>
</template>

<script>
import {sortBy} from 'lodash'
import {mapState, mapGetters} from 'vuex'

import {mapResources} from '@helpers/vuex'

import CropCard from './CropCard'
import CropUsageCard from './CropUsageCard'
import CropHarvestQualityCard from './CropHarvestQualityCard'

export default {
  components: {
    CropCard,
    CropUsageCard,
    CropHarvestQualityCard
  },
  computed: {
    ...mapState('masterData/cropManagement', {
      selection: state => state.selection,
      current: state => state.editBuffer
    }),
    ...mapGetters('masterData', [
      'cropArray',
      'cropUsageArray',
      'cropHarvestQualityArray'
    ]),
    ...mapGetters('masterData/cropManagement', [
      'location',
      'combinedLookup',
      'countsById'
    ]),
    ...mapState('i18n', [
      'translations'
    ]),
    ...mapResources({
      cropTranslations: 'masterData.crops.crop',
      usageTranslations: 'masterData.crops.usage',
      qualityTranslations: 'masterData.crops.quality'
    }),
    listItems () {
      switch (this.location.selectionLevel) {
      case null: {
        return this.cropArray
      }
      case 'crop': {
        return this.cropUsageArray.filter(x => x.cropId === this.location.cropId)
      }
      case 'usage':
      case 'quality': {
        return this.cropHarvestQualityArray.filter(x => x.cropUsageId === this.location.cropUsageId)
      }
      default: return []
      }
    },
    listLevel () {
      return {
        null: 'crop',
        crop: 'usage',
        usage: 'quality',
        quality: 'quality'
      }[this.location.selectionLevel]
    },
    sortedListItems () {
      const getName = (item, namespace) => {
        const key = `${item.id}_name`
        return this.translations[namespace] && this.translations[namespace][key]
          ? this.translations[namespace][key].toString()
          : ''
      }

      return sortBy(this.listItems, x => getName(x, `masterData.crops.${this.listLevel}`))
    }
  }
}
</script>

<style lang="scss" scoped>
.navigation-list {
  flex: 1;
  display: flex;
  flex-direction: column;

  overflow-y: auto;

  border-top: 1px solid gray;
}
</style>
