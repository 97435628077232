<template lang="html">
  <div class="crop-management">
    <template v-if="loaded">
      <header>
        <IxRes>masterData.crops.headers.cropManagement</IxRes>
      </header>
      <main>
        <CropNavigation :has-changes="hasChanges" />

        <DetailsPanel
          v-if="editBuffer"
          :has-changes="hasChanges"
          @reset="reset"
          @save="onSave"
        />
        <LandingPage v-else />
      </main>
    </template>

    <TranslationControls />

    <FrsLoadingIndicator :loading="!loaded" />
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import TranslationControls from '@components/translation/TranslationControls'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import CropNavigation from './components/crop-management/CropNavigation'
import DetailsPanel from './components/crop-management/DetailsPanel'
import LandingPage from './components/crop-management/LandingPage'

import ChangeDetectionMixin from '@components/forms/ChangeDetectionMixin'
import FormPartMixin from '@components/forms/FormPartMixin'

export default {
  components: {
    TranslationControls,
    FrsLoadingIndicator,
    CropNavigation,
    DetailsPanel,
    LandingPage
  },
  mixins: [
    ChangeDetectionMixin,
    FormPartMixin
  ],
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    ...mapState('masterData/cropManagement', [
      'editBuffer'
    ])
  },
  methods: {
    ...mapActions('masterData/cropManagement', [
      'reloadRequiredData',
      'save'
    ]),
    async onSave () {
      this.validate()

      await this.$nextTick()

      if (this.state === 'success') {
        await this.save()
      }
    }
  },
  created () {
    this.reloadRequiredData()
      .then(() => {
        this.loaded = true
      })
  }
}
</script>

<style lang="scss" scoped>
.crop-management {
  position: relative;

  flex: 1;
  display: flex;
  flex-direction: column;

  header {
    text-transform: uppercase;
    border-bottom: 1px solid gray;

    font-size: 2em;
    padding: 0.25em;
  }

  main {
    flex: 1;
    display: flex;

    min-height: 0;
  }
}
</style>
