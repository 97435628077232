<template lang="html">
  <div class="details-panel">
    <header>
      <IxRes>masterData.crops.headers.details_{{ location.bufferLevel }}</IxRes> <i v-if="editBuffer.isArchived" class="fa fa-archive" title="archived" />
    </header>

    <div class="form-container">
      <component :is="component" :key="editBufferHash" />

      <DisabledOverlay v-if="editBuffer.isArchived" />
    </div>

    <footer>
      <IxButton
        large save
        class="save-button"
        :disabled="editBuffer.isArchived"
        @click="$emit('save')"
      />

      <template v-if="editBuffer.id">
        <IxButton
          class="reset-button"
          large icon="undo"
          :disabled="editBuffer.isArchived || !hasChanges"
          @click="$emit('reset')"
        >
          <IxRes>masterData.crops.buttons.reset</IxRes>
        </IxButton>
        <IxButton
          v-if="editBuffer.isArchived"
          large restore
          @click="restore"
        />
        <IxButton
          v-else
          large remove
          @click="archive"
        />
      </template>
      <IxButton
        v-else
        class="reset-button"
        large icon="undo"
        @click="cancelCreate"
      >
        <IxRes>masterData.crops.buttons.discard</IxRes>
      </IxButton>
    </footer>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

import IxButton from '@components/IxButton'
import DisabledOverlay from '@components/DisabledOverlay'

import CropForm from './CropForm'
import CropUsageForm from './CropUsageForm'
import CropHarvestQualityForm from './CropHarvestQualityForm'

export default {
  components: {
    IxButton,
    DisabledOverlay,
    CropForm,
    CropUsageForm,
    CropHarvestQualityForm
  },
  props: {
    hasChanges: Boolean
  },
  computed: {
    ...mapState('masterData/cropManagement', {
      editBuffer: state => state.editBuffer,
      editBufferHash: state => state.ui.editBufferHash
    }),
    ...mapGetters('masterData/cropManagement', [
      'location'
    ]),
    component () {
      switch (this.location.bufferLevel) {
      case 'crop': return CropForm
      case 'usage': return CropUsageForm
      case 'quality': return CropHarvestQualityForm
      default: throw new Error('invalid level')
      }
    }
  },
  methods: {
    ...mapActions('masterData/cropManagement', [
      'archive',
      'restore',
      'cancelCreate'
    ])
  }
}
</script>

<style lang="scss" scoped>
.details-panel {
  flex: 1;
  display: flex;
  flex-direction: column;

  header {
    text-transform: uppercase;
    border-bottom: 1px solid gray;

    font-size: 1.5em;
    font-weight: lighter;
    padding: 0.25em;
  }

  .form-container {
    position: relative;

    flex: 1;
    display: flex;
    flex-direction: column;

    .archived-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: whitesmoke;
      opacity: 0.5;
    }
  }

  footer {
    display: flex;

    padding: 0.5em;
    border-top: 1px solid gray;

    .save-button {
      margin-right: 10em;
    }
    .reset-button {
      margin-right: 1em;
    }
  }
}
</style>
