<template>
  <div class="product-container">
    <ProductDetails />
    <br>

    <VueSelectize
      v-model="tags"
      :options="tagOptions"
      :fields="tagFields"
      name="tag-selectize"
      multiple
      allow-empty
    >
      <template #label>
        <IxRes>masterData.product.labels.tags</IxRes>:
      </template>
    </VueSelectize>

    <BsRadioList
      v-model="isOrganic" :options="[false, true]"
      name="isOrganic"
      compact
    >
      <template #default="{option}">
        <IxRes>masterData.{{ option ? 'organic' : 'mineral' }}</IxRes>
      </template>
      <template #label>
        <IxRes>masterData.typeOfFertilizer</IxRes>:
      </template>
    </BsRadioList>
    <br>
    <BsRadioList
      v-model="isLiquid" :options="[false, true]"
      name="isLiquid"
      compact
    >
      <template #default="{option}">
        <IxRes>masterData.{{ option ? 'liquid' : 'solid' }}</IxRes>
      </template>
      <template #label>
        <IxRes>masterData.consistency</IxRes>:
      </template>
    </BsRadioList>
    <template v-if="isOrganic">
      <BsCheckbox v-model="isComposted" inline name="isComposted">
        <IxRes>masterData.labels.isComposted</IxRes>
      </BsCheckbox>
      <br>
      <VueSelectize
        v-model="consistency"
        :options="consistencyData"
        :fields="consistencyFields"
        name="fertilizerConsistency"
        allow-empty
      >
        <template #label>
          <IxRes>masterData.labels.fertilizerConsistency</IxRes>
        </template>
      </VueSelectize>
      <VueSelectize
        v-model="fertilizerSource"
        :options="sourceData"
        :fields="sourceFields"
        name="fertilizerSource"
        allow-empty
      >
        <template #label>
          <IxRes>masterData.labels.fertilizerSource</IxRes>
        </template>
      </VueSelectize>
    </template>
    <br>
    <div v-if="isLiquid" class="density-container">
      <NumericInput
        v-model="density"
        unit="kg/l"
        name="density"
      >
        <div slot="label" class="density-label">
          <div>
            <IxRes>
              masterData.density
            </IxRes>
            :
          </div>
          <IxRes>
            masterData.seed.optional
          </IxRes>
        </div>
      </NumericInput>
    </div>
    <br>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

import {consistency, source} from 'src/js/store/modules/master-data/fertilizerProperties.js'

import ProductDetails from './ProductDetails'
import BsRadioList from '@components/bootstrap/BsRadioList'
import BsCheckbox from '@components/bootstrap/BsCheckbox'
import NumericInput from '@components/forms/NumericInput'
import VueSelectize from '@components/VueSelectize'

export default {
  components: {
    NumericInput,
    ProductDetails,
    BsRadioList,
    BsCheckbox,
    VueSelectize
  },
  computed: {
    ...mapState('masterData', {
      productTags: state => state.productTags
    }),
    ...mapState('masterData/management', {
      id: state => state.editBuffer.id
    }),
    ...mapFormFields('masterData/management', [
      'editBuffer.isLiquid',
      'editBuffer.isOrganic',
      'editBuffer.density',
      'editBuffer.isComposted',
      'editBuffer.consistency',
      'editBuffer.fertilizerSource',
      'editBuffer.tags'
    ]),
    consistencyData () {
      return consistency
    },
    sourceData () {
      return source
    },
    consistencyFields () {
      return {
        text: id => this.$i18n.translate(`masterData.fertilizerProperties.consistency_${id}`)
      }
    },
    sourceFields () {
      return {
        text: id => this.$i18n.translate(`masterData.fertilizerProperties.source_${id}`)
      }
    },
    tagOptions () {
      if (!this.productTags.length) return []

      return this.productTags.filter(tag => {
        const splittedTag = tag.split(':')
        if (splittedTag[0] === 'Dünger') {
          return tag
        }
      })
    },
    tagFields () {
      return {
        text: tag => {
          const splittedTag = tag.split(':')
          return splittedTag[splittedTag.length - 1]
        },
        value: tag => tag
      }
    }
  }
}
</script>

<style scoped>
.product-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.button-group {
  margin-top: 16px;
}
.density-container {
  margin-top: 16px;
  margin-bottom: 16px;
  width: 200px;
}
.density-label {
  width: 120px;
  display: flex;
  justify-content: space-between;
}
</style>
