export const consistency = [
  'liquidManure', // 'Jauche'
  'semiLiquidManure', // Gülle'
  'dung', // 'Mist'
  'digestate', // 'Gärrückstände',
  'rottenSewageSludge', // 'ausgefaulter Klärschlamm',
  'limeStabilizedSewageSludge' // 'kalkstabilisierter Klärschlamm'
]

export const source = [
  'beef', // 'Rind',
  'pork', // Schwein',
  'poultry', // 'Geflügel',
  'otherSpecies', // 'Andere Tierarten',
  'biogasPlant', // 'Biogasanlage',
  'otherFertilizer', // 'Sonstige Düngemittel',
  'sewagePlant' // 'Kläranlage'
]
