<template lang="html">
  <div class="details">
    <div class="left">
      <slot name="left" />
    </div>
    <div class="right">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.details {
  flex: 1;
  display: flex;

  overflow-y: auto;
  align-items: flex-start;

  .left, .right {
    display: flex;
    flex-direction: column;
  }

  .left {
    width: 33%;
    padding: 10px;
    margin-right: 5em;
  }

  .right {
    flex: 1;
    padding: 10px;

    > :not(:last-child) {
      margin-bottom: 0.5em;
    }
  }
}
</style>
