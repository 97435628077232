<template>
  <div v-if="displayNewProduct" class="new-product">
    <ListCard
      :id="productName"
      selected show-avatar
      :title="productName"
      :subtitle="selectedType"
    >
      <template #avatar>
        <i class="fa fa-pencil-square-o" />
      </template>
    </ListCard>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'

import ListCard from '@components/card/ListCard'

export default {
  components: {
    ListCard
  },
  computed: {
    ...mapState('masterData/management', {
      productName: state => state.editBuffer ? state.editBuffer.name : '',
      selectedType: state => state.ui.selectedType
    }),
    ...mapGetters('masterData/management', [
      'displayNewProduct'
    ])
  }
}
</script>

<style lang="scss" scoped>

.new-product {
  // to be consistent with the product list itself add the same margin
  margin-bottom: 5px;
}
</style>
