<template lang="html">
  <div class="crop-navigation">
    <div class="search-wrapper">
      <Search
        v-model="searchString"
        :data="searchInput"
        :keys="searchKeys"
        :threshold="0.3"
        @result="searchResult = $event"
      />
    </div>

    <SearchResults
      v-if="searchResult"
      :result="splitResult"
      @select="withGuard(() => select($event))"
    />

    <template v-else>
      <CropBreadcrumbs @select="withGuard(() => select($event))" @home="withGuard(() => clearSelection())" />
      <CropNavigationList @select="withGuard(() => select($event))" />
    </template>

    <div class="button-container">
      <IxButton
        large
        icon="plus"
        @click="withGuard(() => create())"
      >
        <IxRes>masterData.crops.buttons.create_{{ creationContext.level }}</IxRes>
      </IxButton>
    </div>
    <DisabledOverlay v-if="disabled" />

    <ConfirmationModal
      ref="modal"
      v-model="showConfirmationModal"
    >
      <template #title>
        <IxRes>masterData.unsavedChanges</IxRes>
      </template>

      <IxRes>masterData.discardChangesQuestion</IxRes>
    </ConfirmationModal>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'

import IxButton from '@components/IxButton'
import DisabledOverlay from '@components/DisabledOverlay'
import Search from '@components/search/Search'
import ConfirmationModal from '@components/modals/ConfirmationModal'

import CropBreadcrumbs from './widgets/CropBreadcrumbs'
import CropNavigationList from './widgets/CropNavigationList'
import SearchResults from './widgets/SearchResults'

export default {
  components: {
    IxButton,
    DisabledOverlay,
    Search,
    CropBreadcrumbs,
    CropNavigationList,
    SearchResults,
    ConfirmationModal
  },
  props: {
    hasChanges: Boolean
  },
  data () {
    return {
      searchKeys: ['name'],
      searchString: '',
      searchResult: null,
      showConfirmationModal: false
    }
  },
  computed: {
    ...mapState('masterData/cropManagement', [
      'editBuffer'
    ]),
    ...mapGetters('masterData/cropManagement', [
      'creationContext'
    ]),
    ...mapState('masterData', [
      'crops',
      'cropUsages',
      'cropHarvestQualities'
    ]),
    ...mapGetters('masterData', [
      'cropArray',
      'cropUsageArray',
      'cropHarvestQualityArray'
    ]),
    ...mapState('i18n', [
      'translations'
    ]),
    searchInput () {
      const getSearchObject = (item, level) => {
        const {id} = item
        const namespace = `masterData.crops.${level}`
        const key = `${id}_name`
        const name = this.translations[namespace] && this.translations[namespace][key]
          ? this.translations[namespace][key].toString()
          : id

        return {id, level, name}
      }

      return this.cropArray.map(x => getSearchObject(x, 'crop'))
        .concat(this.cropUsageArray.map(x => getSearchObject(x, 'usage')))
        .concat(this.cropHarvestQualityArray.map(x => getSearchObject(x, 'quality')))
    },
    splitResult () {
      if (!this.searchResult) return null

      return {
        crops: this.searchResult.filter(x => x.id in this.crops).map(({id}) => this.crops[id]),
        usages: this.searchResult.filter(x => x.id in this.cropUsages).map(({id}) => this.cropUsages[id]),
        qualities: this.searchResult.filter(x => x.id in this.cropHarvestQualities).map(({id}) => this.cropHarvestQualities[id])
      }
    },
    disabled () {
      return this.editBuffer && !this.editBuffer.id
    }
  },
  methods: {
    ...mapActions('masterData/cropManagement', [
      'create',
      'select'
    ]),
    ...mapMutations('masterData/cropManagement', [
      'clearSelection'
    ]),
    withGuard (action) {
      return this.waitForConfirmationIfNecessary()
      .then(action)
      .catch(() => {})
    },
    waitForConfirmationIfNecessary () {
      return this.hasChanges
        ? this.$refs.modal.show()
        : Promise.resolve()
    }
  }
}
</script>

<style lang="scss" scoped>
.crop-navigation {
  width: 400px;
  display: flex;
  flex-direction: column;
  position: relative;

  border-right: 1px solid gray;

  transition: filter 0.25s;

  .button-container {
    display: flex;
    justify-content: center;

    border-top: 1px solid gray;

    padding: 0.5em;
  }

  .search-wrapper {
    padding: 5px;
  }
}
</style>
