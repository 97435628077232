<template>
  <ListCard
    :title="product.name"
    :selected="selected"
    :show-avatar="showAvatar"
  >
    <template v-if="$slots.subtitle" #subtitle>
      <slot name="subtitle" />
    </template>
    <template v-else #subtitle>
      {{ metaDataInfo }}
    </template>
    <template slot="avatar">
      <slot name="avatar" />
    </template>
  </ListCard>
</template>

<script>
import ListCard from '@components/card/ListCard'
import {toProductMetadataInfo} from '@md/product-metadata'

export default {
  components: {
    ListCard
  },
  props: {
    product: Object,
    selected: Boolean,
    showAvatar: Boolean
  },
  computed: {
    metaDataInfo () {
      return toProductMetadataInfo(this.product)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
