<template>
  <div class="product-details">
    <LabelAndMessage required>
      <template #label>
        <IxRes>masterData.productName</IxRes>:
      </template>
      <TextInput
        v-model="name" name="name"
        :placeholder="actualPlaceholder"
        required
      />
    </LabelAndMessage>

    <LabelAndMessage>
      <template #label>
        <IxRes>masterData.synonym</IxRes>:
      </template>
      <TextInput v-model="short" name="short" />
    </LabelAndMessage>
    <LabelAndMessage>
      <template #label>
        <IxRes>masterData.note</IxRes>:
      </template>
      <TextInput
        v-model="note" multiline
        name="note"
      />
    </LabelAndMessage>
  </div>
</template>

<script>
import {mapFormFields, mapResources} from '@helpers/vuex'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'

import TextInput from '@components/forms/TextInput'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'

export default {
  components: {
    TextInput,
    LabelAndMessage
  },
  mixins: [
    StandardRulesMixin
  ],
  computed: {
    ...mapResources([
      'masterData'
    ]),
    ...mapFormFields('masterData/management', [
      'editBuffer.name',
      'editBuffer.note',
      'editBuffer.short'
    ]),
    actualPlaceholder () {
      return this.masterData.productName
    }
  }
}
</script>

<style lang="scss" scoped>
.product-details {
  display: flex;
  flex-direction: column;
  > *:not(:last-child) {
    margin-bottom: 20px;
  }
}
</style>
