<template>
  <div>
    <div class="vulnerabilities-grades">
      <template v-if="vulnerabilities">
        <div v-for="key in Object.keys(vulnerabilities)" :key="`key_${key}`" :class="hasPermissionForEditing ? 'vulnerabilitiy-item' : ''">
          <NumericInput
            :value="vulnerabilities[key].value"
            :name="`grade_${key}`"
            :readonly="!hasPermissionForEditing"
            @input="setVulnerabilityValue({name: vulnerabilities[key].characteristic, value: $event})"
          >
            <template #label>
              <IxRes>masterData.seed.labels.vulnerability_{{ key }}</IxRes>
            </template>
          </NumericInput>
          <div v-if="hasPermissionForEditing" class="icon-trash" :title="$i18n.translate('masterData.seed.titles.removeVulnerability')">
            <FontAwesomeIcon :icon="icons.trash" @click="prepareForDelete(key)" />
          </div>
        </div>
      </template>
      <div v-if="hasPermissionForEditing && hasOptions" class="add-container" :title="$i18n.translate('masterData.seed.titles.addVulnerability')">
        <FontAwesomeIcon :icon="icons.plus" @click="openAddModal" />
      </div>
    </div>
    <br>

    <BsModal v-model="showAddModal">
      <template #title>
        <IxRes>masterData.seed.titles.addVulnerability</IxRes>
      </template>

      <VueSelectize
        v-model="newCharacteristic"
        name="char-select"
        :options="filteredCharacteristicOptions"
        :fields="characteristicsFields"
      >
        <template #label>
          <IxRes>masterData.seed.labels.characteristics</IxRes>:
        </template>
      </VueSelectize>

      <NumericInput v-model="newCharacteristicValue" name="characteristicValue" :rule="min(1)">
        <template #label>
          <IxRes>masterData.seed.labels.characteristicValue</IxRes>:
        </template>
      </NumericInput>

      <template slot="footer">
        <IxButton
          icon="check" save
          large
          :disabled="!(newCharacteristicValue > 0 && newCharacteristic)"
          @click="addNewCharacteristic"
        >
          <IxRes>masterData.seed.labels.addCharacteristic</IxRes>
        </IxButton>
        <IxButton cancel large @click="showAddModal = false" />
      </template>
    </BsModal>

    <BsModal v-model="showDeleteModal">
      <template #title>
        <IxRes>masterData.seed.titles.removeVulnerability</IxRes>
      </template>

      <IxRes :context="{vulnerability: deleteContext}">masterData.seed.hints.removeVulnerability</IxRes>

      <template slot="footer">
        <IxButton
          icon="check" save
          large
          @click="removeVulnerability"
        >
          <IxRes>masterData.seed.labels.removeVulnerability</IxRes>
        </IxButton>
        <IxButton cancel large @click="abortDelete" />
      </template>
    </BsModal>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'

import NumericInput from '@components/forms/NumericInput'
import BsModal from '@components/bootstrap/BsModal'
import IxButton from '@components/IxButton'
import VueSelectize from '@components/VueSelectize'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faTrashAlt, faPlusCircle} from '@fortawesome/free-solid-svg-icons'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'
import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    VueSelectize,
    IxButton,
    NumericInput,
    FontAwesomeIcon,
    BsModal
  },
  mixins: [
    StandardRulesMixin,
    PermissionMixin
  ],
  props: {
    product: Object
  },
  data () {
    return {
      showAddModal: false,
      showDeleteModal: false,
      hasOptions: true,
      newCharacteristic: null,
      newCharacteristicValue: null,
      selectedVulnerability: null
    }
  },
  computed: {
    ...mapState('masterData', {
      vulnerabilities: state => state.management.editBuffer.vulnerabilities,
      characteristics: state => state.productCharacteristics
    }),
    hasPermissionForEditing () {
      return this.$can('GlobalProductAdministration')
    },
    filteredCharacteristicOptions () {
      if (!this.vulnerabilities) return this.characteristics

      return this.characteristics.filter(characteristic => !Object.keys(this.vulnerabilities).includes(characteristic))
    },
    characteristicsFields () {
      return {
        text: characteristic => this.$i18n.translate(`masterData.seed.labels.vulnerability_${characteristic}`),
        value: characteristic => characteristic
      }
    },
    deleteContext () {
      return this.$i18n.translate(`masterData.seed.labels.vulnerability_${this.selectedVulnerability}`)
    },
    icons () {
      return {
        trash: faTrashAlt,
        plus: faPlusCircle
      }
    }
  },
  methods: {
    ...mapMutations('masterData/management', [
      'setVulnerabilityValue',
      'setVulnerability',
      'deleteVulnerability'
    ]),
    openAddModal () {
      this.newCharacteristicValue = null
      this.showAddModal = !this.showAddModal
    },
    addNewCharacteristic () {
      this.setVulnerability({name: this.newCharacteristic, value: this.newCharacteristicValue})
      this.showAddModal = false
    },
    prepareForDelete (vulnerability) {
      this.selectedVulnerability = vulnerability
      this.showDeleteModal = true
    },
    removeVulnerability () {
      this.deleteVulnerability(this.selectedVulnerability)
      this.showDeleteModal = false
    },
    abortDelete () {
      this.showDeleteModal = false
      this.selectedVulnerability = null
    }
  },
  watch: {
    filteredCharacteristicOptions: {
      immediate: true,
      handler (value) {
        if (!value.length) {
          this.hasOptions = false
        } else {
          this.newCharacteristic = value[0]
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.vulnerabilities-grades {
  width: 80%;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  > * {
    margin-right: 5px;
  }
}
.vulnerabilitiy-item {
  display: flex;
  padding: 0 4px;
  border-right: 1px solid darken(#eeeeee, 20%);

  .icon-trash {
    color: darken(#eeeeee, 10%);
    margin: 2px 2px 0 2px;

    &:hover {
      cursor: pointer;
      color: darken(#eeeeee, 20%);
    }
  }
}
.add-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  color: darken(#eeeeee, 10%);
  margin-left: 4px;
  margin-top: 4px;
  font-size: 3em;

  &:hover {
    color: darken(#eeeeee, 20%);
    cursor: pointer;
  }

  > * {
    margin-top: 2px;
  }
}
</style>
