<template>
  <div class="product-sales-navigation">
    <FrsLoadingIndicator :loading="!initialLoadFinished" :error="initialLoadingError" large>
      <span slot="error">Something went wrong during loading, please reload</span>
    </FrsLoadingIndicator>
    <template v-if="initialLoadFinished">
      <ProductSalesManagementContainer />
    </template>

    <TranslationControls />
  </div>
</template>

<script>
import {mapActions} from 'vuex'

import {reduceHeader} from 'src/coffee/helpers/layout'

import ProductSalesManagementContainer from './components/product-sales-management/ProductSalesManagementContainer'

import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import TranslationControls from '@components/translation/TranslationControls'

import SelfDestruct from '@mixins/SelfDestruct'

export default {
  components: {
    FrsLoadingIndicator,
    ProductSalesManagementContainer,
    TranslationControls
  },
  mixins: [
    SelfDestruct
  ],
  data () {
    return {
      initialLoadFinished: false,
      initialLoadingError: false
    }
  },
  methods: {
    ...mapActions('masterData/management', [
      'preloadStaticData'
    ])
  },
  created () {
    reduceHeader()
    Promise.all([
      this.$store.dispatch('i18n/preloadTranslations', [
        'Common.SR_Common',
        'Enums.SR_Enums',
        'Controls.Placeholders.SR_Placeholders',
        'Areas.FieldRecordSystem.SR_FieldRecordSystem',
        'Messages.State.SR_StateMessages',
        'ModelProperties.SR_ModelProperties',
        'Messages.Error.SR_ErrorMessages',
        'Messages.Info.SR_InfoMessages',
        'Areas.MasterData.SR_MasterData',
        'masterData',
        'frs.units'
      ]),
      this.preloadStaticData()
    ])
    .then(() => {
      this.initialLoadFinished = true
    })
    .catch(error => {
      console.error(error)
      this.initialLoadFinished = true
      this.initialLoadingError = true
    })
  }
}
</script>

<style lang="scss" scoped>
.product-sales-navigation {
  position: relative;
  display: flex;
  flex: 1;
  padding: 20px;
  width: 100%;
}
</style>
