<template lang="html">
  <div class="crop-breadcrumbs">
    <a
      v-if="selection"
      class="home"
      href="#"
      @click.prevent="$emit('home')"
    >
      <i class="fa fa-home" />
      <IxRes>masterData.crops.breadcrumbs.home</IxRes>
    </a>

    <template v-for="({level, id}) of levelsWithIds">
      <BreadCrumbsLink
        v-if="id"
        :id="id" :key="level"
        :level="level" :indent="getIndent(level, selection)"
        :is-current="id === selection"
        @click="$emit('select', id)"
      />
      <BreadcrumbsSummary
        v-else :key="level"
        :level="level" :indent="getIndent(level, selection)"
        :count="countsById[selection][level]"
        :show-icon="level !== 'crop' || selection"
      />
    </template>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

import BreadCrumbsLink from './BreadcrumbsLink'
import BreadcrumbsSummary from './BreadcrumbsSummary'

export default {
  components: {
    BreadCrumbsLink,
    BreadcrumbsSummary
  },
  computed: {
    ...mapState('masterData/cropManagement', [
      'selection'
    ]),
    ...mapGetters('masterData/cropManagement', [
      'location',
      'countsById'
    ]),
    levels () {
      return ['crop', 'usage', 'quality']
    },
    levelsWithIds () {
      return this.levels.map(level => ({level, id: this.getIdForLevel(level)}))
    }
  },
  methods: {
    ...mapActions('masterData/cropManagement', [
      'select'
    ]),
    getIndent (level, selection) {
      switch (level) {
      case 'crop': return 1
      case 'usage': return selection ? 2 : 1
      case 'quality': return selection ? 3 : 2
      default: throw new Error(`invalid level: ${level}`)
      }
    },
    getIdForLevel (level) {
      switch (level) {
      case 'crop': return this.location.cropId
      case 'usage': return this.location.cropUsageId
      case 'quality': return this.location.cropHarvestQualityId
      default: throw new Error(`invalid level: ${level}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.crop-breadcrumbs {
  padding: 0.5em;

  .home .fa {
    margin: 0 0.2em;
  }
}
</style>
