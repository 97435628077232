export default {
  props: {
    indent: {
      type: Number,
      required: true
    }
  },
  computed: {
    style () {
      return {
        marginLeft: `${1.5 * this.indent}em`
      }
    }
  }
}
