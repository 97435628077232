import 'src/coffee/areas/master-data' // legacy registration

import {registerComponent} from '@helpers/vue'
import ProductManagement from 'src/vue/areas/master-data/ProductManagement'
import ProductSalesManagement from 'src/vue/areas/master-data/ProductSalesManagement'
import CropManagement from 'src/vue/areas/master-data/CropManagement'

registerComponent(ProductManagement, 'master-data/product-management')
registerComponent(ProductSalesManagement, 'master-data/product-sales-management')
registerComponent(CropManagement, 'master-data/crop-management')
