<template>
  <div class="management-container">
    <RecommendedProductsContainer class="recommended-product-container" />
  </div>
</template>

<script>
import RecommendedProductsContainer from './RecommendedProductsContainer'

export default {
  components: {
    RecommendedProductsContainer
  }
}
</script>

<style lang="scss" scoped>
.management-container {
  display: flex;
  flex: 1;
  width: 100%;

  .recommended-product-container {
    width: 50%;
    padding-right: 16px;
    border-right: 1px solid lightgrey;
  }
}
</style>
