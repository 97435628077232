<template lang="html">
  <LabelAndMessage :required="required">
    <div class="share-grid">
      <NumericInput
        class="left"
        :name="`${name}-mainproductShare`"
        :value="value.mainproductShare"
        :required="required"
        @input="$emit('input', {...value, mainproductShare: $event})"
      />
      <div class="center input-divider">
        :
      </div>
      <NumericInput
        class="right"
        :name="`${name}-mainproductShare`"
        :value="value.byproductShare"
        :required="required"
        @input="$emit('input', {...value, byproductShare: $event})"
      />

      <div class="left">
        <IxRes>masterData.crops.labels.mainproductShare</IxRes>
      </div>
      <span class="center">:</span>
      <div class="right">
        <IxRes>masterData.crops.labels.byproductShare</IxRes>
      </div>
    </div>
    <template #label>
      <slot name="label" />
    </template>
  </LabelAndMessage>
</template>

<script>
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import NumericInput from '@components/forms/NumericInput'

import FormPartMixin from '@components/forms/FormPartMixin'

export default {
  components: {
    LabelAndMessage,
    NumericInput
  },
  mixins: [
    FormPartMixin
  ],
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: Object, // {mainproductShare, byproductShare}
      required: true
    },
    required: Boolean
  }
}
</script>

<style lang="scss" scoped>
.share-grid {
  display: grid;
  grid-template: auto auto / 1fr auto 1fr;

  ::v-deep .input-field {
    width: 100px;
  }

  .left {
    justify-self: end;

    ::v-deep .input-field {
      margin-left: auto;

      input {
        text-align: right;
        padding-right: 12px;
      }

      .glyphicon {
        right: unset;
      }
    }
  }

  .center {
    justify-self: center;
    margin: 0 0.2em;

    &.input-divider {
      font-size: 2em;
      align-self: start;
      margin-top: -0.2em;
    }
  }

  .right {
    justify-self: start;
  }
}
</style>
