<template>
  <div class="btn-group grouping-container">
    <ToggleButton v-if="selectedType === 'fertilizer'" v-model="groupKey" :value="'isOrganic'">
      <IxRes>masterData.navigation.grouping.type</IxRes>
    </ToggleButton>
    <ToggleButton v-if="selectedType === 'fertilizer'" v-model="groupKey" :value="'isLiquid'">
      <IxRes>masterData.navigation.grouping.consistency</IxRes>
    </ToggleButton>
    <ToggleButton v-if="selectedType === 'protection'" v-model="groupKey" :value="'category'">
      <IxRes>masterData.navigation.grouping.category</IxRes>
    </ToggleButton>
    <ToggleButton v-if="selectedType === 'protection'" v-model="groupKey" :value="'usage'">
      <IxRes>masterData.navigation.grouping.usage</IxRes>
    </ToggleButton>
  </div>
</template>

<script>

import {mapState} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

import ToggleButton from '@components/ToggleButton'

export default {
  components: {
    ToggleButton
  },
  computed: {
    ...mapFormFields('masterData/management', [
      'ui.groupKey'
    ]),
    ...mapState('masterData/management', {
      selectedType: state => state.ui.selectedType
    })
  }
}
</script>

<style lang="scss" scoped>
.grouping-container {
  margin: 10px 0 0 0;
}

</style>
