<template lang="html">
  <div class="mineral-container">
    <div class="item">
      <p><IxRes>masterData.nAll</IxRes></p>
      <NumericInput
        v-model="nitrogen"
        name="nitrogen"
        percent percent-not-normalized
        :rule="between(0, 100)"
        hide-description
      />
    </div>

    <OxideFormWidget />

    <div class="item first-item">
      <p><IxRes>masterData.calcium</IxRes></p>
      <NumericInput
        v-model="lime"
        name="lime"
        percent percent-not-normalized
        :rule="between(0, 100)"
        hide-description
      />
    </div>
    <div class="item">
      <p><IxRes>masterData.sulfur</IxRes></p>
      <NumericInput
        v-model="sulfur"
        name="sulfur"
        percent percent-not-normalized
        :rule="between(0, 100)"
        hide-description
      />
    </div>
    <div class="item">
      <p><IxRes>masterData.iron</IxRes></p>
      <NumericInput
        v-model="iron"
        name="iron"
        percent percent-not-normalized
        :rule="between(0, 100)"
        hide-description
      />
    </div>
    <div class="item">
      <p><IxRes>masterData.manganese</IxRes></p>
      <NumericInput
        v-model="manganese"
        name="manganese"
        percent percent-not-normalized
        :rule="between(0, 100)"
        hide-description
      />
    </div>
    <div class="item">
      <p><IxRes>masterData.zinc</IxRes></p>
      <NumericInput
        v-model="zinc"
        name="zinc"
        percent percent-not-normalized
        :rule="between(0, 100)"
        hide-description
      />
    </div>
    <div class="item">
      <p><IxRes>masterData.boron</IxRes></p>
      <NumericInput
        v-model="boron"
        name="boron"
        percent percent-not-normalized
        :rule="between(0, 100)"
        hide-description
      />
    </div>
    <div class="item">
      <p><IxRes>masterData.copper</IxRes></p>
      <NumericInput
        v-model="copper"
        name="copper"
        percent percent-not-normalized
        hide-description
        :rule="between(0, 100)"
      />
    </div>
    <div class="item">
      <p><IxRes>masterData.molybdenum</IxRes></p>
      <NumericInput
        v-model="molybdenum"
        name="molybdenum"
        percent percent-not-normalized
        :rule="between(0, 100)"
        hide-description
      />
    </div>
    <div class="item">
      <p><IxRes>masterData.tanContent</IxRes></p>
      <NumericInput
        v-model="tanContent"
        addon="kg TAN / kg N"
        name="tanContent"
        :rule="min(0)"
        hide-description
      />
    </div>
  </div>
</template>

<script>

import {mapFormFields} from '@helpers/vuex'

import NumericInput from '@components/forms/NumericInput'
import OxideFormWidget from './OxideFormWidget'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'

export default {
  components: {
    NumericInput,
    OxideFormWidget
  },
  mixins: [StandardRulesMixin],
  computed: {
    ...mapFormFields('masterData/management', [
      'editBuffer.nitrogen',
      'editBuffer.lime',
      'editBuffer.sulfur',
      'editBuffer.iron',
      'editBuffer.manganese',
      'editBuffer.zinc',
      'editBuffer.boron',
      'editBuffer.copper',
      'editBuffer.molybdenum',
      'editBuffer.tanContent'
    ])
  }
}
</script>

<style scoped>
p {
  text-align: right;
  margin-right: 6px;
  padding: 5px 0 0 0;
  width: 200px;
}
.mineral-container {
  display: flex;
  flex-direction: column;
  width: 60%;
}
.oxide {
  margin-left: 150px;
  margin-bottom: 8px;
}
.item {
  display: flex;
  margin-bottom: 8px;
}
.first-item {
  margin-top: 16px;
}
</style>
