<template>
  <div class="product-sales-management-container">
    <div class="product-naviagtion-container">
      <h3>
        <IxRes>masterData.labels.allProducts</IxRes>
      </h3>
      <NavigationHeaderSearch :products="productsWithoutRecommended" @searchResult="searchResultAll = $event" />
      <div class="type-selection">
        <a v-for="type in ['seed', 'fertilizer', 'protection', 'all']" :key="type" @click="selectedTypeAll = type">
          <IxRes>Enums.SR_Enums.ProductGroup_{{ type }}</IxRes>
        </a>
      </div>
      <NavigationHeaderSelectionInfo :selected="selectedProductIds" :filtered="filteredProductsWithoutRecommended" />
      <RecycleScroller
        v-slot="{item}"
        class="scroller" :items="filteredProductsWithoutRecommended"
        :item-size="64" key-field="id"
      >
        <ListCard
          :id="item.id"
          :title="item.name"
          :subtitle="$i18n.translate(`masterData.${item.type}`)"
          :selected="selectedProductIds.includes(item.id)"
          @click.native="setSelectedProductIds(item.id)"
        />
      </RecycleScroller>
    </div>
    <div class="switch-status-container">
      <div :title="$i18n.translate('masterData.hints.addToRecommendedProducts')">
        <FontAwesomeIcon
          class="toggle-icon" :class="{'icon-disabled': !selectedProductIds.length}"
          :icon="icons.arrowRight"
          @click="setRecommendedProducts(selectedProductIds)"
        />
      </div>
      <div :title="$i18n.translate('masterData.hints.removeFromRecommendedProducts')">
        <FontAwesomeIcon
          class="toggle-icon" :class="{'icon-disabled': !selectedRecommendedProductIds.length}"
          :icon="icons.arrowLeft"
          @click="deleteRecommendedProducts(selectedRecommendedProductIds)"
        />
      </div>
    </div>
    <div class="recommended-product-naviagtion-container">
      <h3>
        <IxRes>masterData.labels.recommendedProducts</IxRes>
      </h3>
      <NavigationHeaderSearch :products="recommendedProducts" @searchResult="searchResultRecommended = $event" />
      <div class="type-selection">
        <a v-for="type in ['seed', 'fertilizer', 'protection', 'all']" :key="type" @click="selectedTypeRecommended = type">
          <IxRes>Enums.SR_Enums.ProductGroup_{{ type }}</IxRes>
        </a>
      </div>
      <NavigationHeaderSelectionInfo :selected="selectedRecommendedProductIds" :filtered="filteredRecommendedProducts" />
      <RecycleScroller
        v-slot="{item}"
        class="scroller" :items="filteredRecommendedProducts"
        :item-size="64" key-field="id"
      >
        <ListCard
          :id="item.id"
          :title="item.name"
          :subtitle="$i18n.translate(`masterData.${item.type}`)"
          :selected="selectedRecommendedProductIds.includes(item.id)"
          @click.native="setSelectedRecommendedProductIds(item.id)"
        />
      </RecycleScroller>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons'

import NavigationHeaderSearch from '../NavigationHeaderSearch'
import NavigationHeaderSelectionInfo from '../NavigationHeaderSelectionInfo'

import ListCard from '@components/card/ListCard'
import {fancyGroup} from '../group-filter'

export default {
  components: {
    FontAwesomeIcon,
    ListCard,
    NavigationHeaderSearch,
    NavigationHeaderSelectionInfo
  },
  data () {
    return {
      searchResultAll: null,
      selectedTypeAll: 'all',
      searchResultRecommended: null,
      selectedTypeRecommended: 'all'
    }
  },
  computed: {
    ...mapFormFields('masterData/management', [
      'ui.groupKey'
    ]),
    ...mapState('masterData', {
      recommendedProductIds: state => state.portalSettings.recommended.products
    }),
    ...mapState('masterData/productSalesManagement', {
      selectedRecommendedProductIds: state => state.selectedRecommendedProductIds,
      selectedProductIds: state => state.selectedProductIds
    }),
    ...mapGetters('masterData', [
      'activeProductArray'
    ]),
    productsWithoutRecommended () {
      return this.activeProductArray
        .filter(product => !this.recommendedProductIds.includes(product.id))
    },
    recommendedProducts () {
      return this.activeProductArray
      .filter(product => this.recommendedProductIds.includes(product.id))
    },
    filteredProductsWithoutRecommended () {
      const filtered = (this.searchResultAll || this.productsWithoutRecommended)
      .filter(product => this.selectedTypeAll === 'all' ? product : product.type === this.selectedTypeAll)

      if (!this.groupKey) return filtered

      return fancyGroup(filtered, this.groupKey, ['usage', 'category'].includes(this.groupKey)).filter(product => !product.hasOwnProperty('isGroupHeader'))
    },
    filteredRecommendedProducts () {
      const filtered = (this.searchResultRecommended || this.recommendedProducts)
      .filter(product => this.selectedTypeRecommended === 'all' ? product : product.type === this.selectedTypeRecommended)

      if (!this.groupKey) return filtered

      return fancyGroup(filtered, this.groupKey, ['usage', 'category'].includes(this.groupKey)).filter(product => !product.hasOwnProperty('isGroupHeader'))
    },
    icons () {
      return {
        arrowLeft: faArrowLeft,
        arrowRight: faArrowRight
      }
    }
  },
  methods: {
    ...mapActions('masterData/productSalesManagement', [
      'setRecommendedProducts',
      'deleteRecommendedProducts'
    ]),
    ...mapMutations('masterData/productSalesManagement', [
      'setSelectedRecommendedProductIds',
      'setSelectedProductIds'
    ])
  }
}
</script>

<style lang="scss" scoped>
.scroller {
  flex: 1;
  padding: 2px 4px;
}
.type-selection {
  display: flex;
  margin-top: 4px;
  cursor: pointer;

  &>* {
    margin-right: 6px;
  }
}
.product-sales-management-container {
  display: flex;
  width: 100%;

  .product-naviagtion-container {
    display: flex;
    flex-direction: column;
    width: 45%;
  }
  .switch-status-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 10%;

    &>* {
      margin: 6px 0;
      font-size: 1.8em;
      color: darkgrey;

      &:hover {
        color: #3d3d3d;
        cursor: pointer;
      }
    }
    .icon-disabled {
      color: lightgrey;
      cursor: not-allowed;
    }
  }
  .recommended-product-naviagtion-container {
    display: flex;
    flex-direction: column;
    width: 45%;
  }
}
</style>
